import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: ''
};

export const fcmSlice = createSlice({
  name: 'tokendata',
  initialState: initialState,
  reducers: {
    updateFcmToken: (state, { payload }) => {
      state.token = payload.token;
      return state;
    },
    removeFcmToken: () => initialState
  }
});
export const { removeFcmToken, updateFcmToken } = fcmSlice.actions;
export const currentFCMToken = (state) => state.token;
export default fcmSlice.reducer;
