import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dateFormatCommon, dateFormatManualAdd } from '@helpers/commonHelpers';
import { useGetAgentList } from '@hooks';
import client from '@libs/HttpClient';
import { useFormikContext, FieldArray, Field } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { toast } from 'react-toastify';

const ContractDetails = (props) => {
  const formik = useFormikContext();
  const defaultValue = (option, value) => {
    return option ? option.find((option) => option.value === value) : '';
  };
  const { t, paymentType, refetch } = props;

  const disableContractDate = useMemo(() => {
    if (formik.values.contract_end_date) {
      const endDate = new Date(formik.values.contract_end_date);
      const today = new Date();
      if (endDate > today && formik.values.mou_contract) {
        return true;
      }
    }
    return false;
  }, [formik.values.contract_end_date, formik.values.mou_contract]);

  let agentOptions = [];
  /**
   * !This Api is for territory drop down
   */
  agentOptions.push({
    value: '',
    label: t('page.add_producer_contract_details_contract_agent_id_option_blank')
  });
  const { isSuccess: agentisSuccess, data: agentList } = useGetAgentList();
  if (agentisSuccess !== false) {
    agentList.data.map((item, i) => {
      return agentOptions.push({ value: item.agent_id, label: item.full_name });
    });
  }

  const generateContractNumber = (date) => {
    const number =
      'CMI/' +
      (paymentType === 'postpaid' ? 'PO' : 'PR') +
      '/' +
      formik.values.producer_id +
      '/' +
      parseInt(moment(date).month() + 1) +
      '/' +
      moment(date).year();

    formik.setFieldValue('contract_number', number);
  };

  const handleContractStartDate = (value) => {
    if (value || value !== '') return value;

    if (formik.values.contract_status === '1') {
      let today = new Date();

      const aYearFromNow = new Date(today);
      formik.setFieldValue(
        'contract_end_date',
        aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1)
      );
      formik.setFieldValue('contract_start_date', today);

      return today;
    }
  };

  /**
   * !This Block is for Delete Attachment
   */
  const handleDeleteClick = (attachment_id, field_name) => {
    if (attachment_id) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h2>{t('page.delete_attachment_popup_message')}</h2>
                <Button
                  className="table-delete-button"
                  onClick={async () => {
                    formik.setFieldValue(field_name, '');
                    formik.setFieldValue(`${field_name}_id`, '');
                    const resp = await client.get(
                      `producer/delete-producer-attachment/${attachment_id}/attachment`
                    );
                    toast.success(resp.message);

                    refetch?.();
                    onClose();
                  }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        }
      });
    } else {
      formik.setFieldValue(`${field_name}_id`, '');
      formik.setFieldValue(`${field_name}_file`, '');
      formik.setFieldValue(`${field_name}_name`, '');
    }
  };

  const download = (image) => {
    const fileName = image.split('/').pop();
    image = image.replace(window.origin, '');
    var link = document.createElement('a');
    link.href = image;
    link.target = '_blank';
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click'));
  };

  const [loadingGenerateMOU, setLoadingGenerateMou] = useState(false);

  const doGenerateMOU = async () => {
    setLoadingGenerateMou(true);
    try {
      const response = await client.post(
        `producer/generate-mou-contract/${formik.values.producer_id}`,
        {
          contract_number: formik.values.contract_number,
          start_date: formik.values.contract_start_date,
          end_date: formik.values.contract_end_date
        }
      );

      download(response.data);
    } catch (err) {
      toast.error(err.message ?? 'gagal genereate mou contract');
      console.error(err);
    }
    setLoadingGenerateMou(false);
  };

  const handleResendMou = async () => {
    toast.info('Mengirimkan Kembali MOU...');
    await client
      .post(`mekari-esign/global-sign-mou/${formik.values.producer_id}`)
      .then(() => toast.success('Mengirim Ulang MOU Berhasil'))
      .catch((err) => toast.error(err));
  };

  const checkValueEmpty = (val, res) => {
    if (!val) toast.error(t(res));
  };

  return (
    <Card className="bg-white rounded">
      <Card.Body>
        <Row>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_producer_contact_details_contract_number_label')}
                {formik.values.contract_number !== formik.initialValues.contract_number ? (
                  <span style={{ color: 'red', marginLeft: '10px' }}>Belum Disimpan!</span>
                ) : undefined}
              </Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.contract_number && formik.errors.contract_number
                    ? 'form-field-error'
                    : formik.touched.contract_number && !formik.errors.contract_number
                    ? 'form-field-success'
                    : '')
                }
                type="text"
                name="contract_number"
                placeholder={t('page.add_producer_contact_details_contract_number_placeholder')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.contract_number}
                disabled={true}
              />
              <div className="form-field-error-text">
                {formik.touched.contract_number && formik.errors.contract_number ? (
                  <div>{t(formik.errors.contract_number)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_producer_contract_details_contract_start_date_label')}
              </Form.Label>
              <DatePicker
                disabled={disableContractDate}
                onKeyDown={dateFormatManualAdd}
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
                className="form-control filter-column"
                placeholderText={dateFormatCommon().toUpperCase()}
                maxDate={
                  formik.values.contract_end_date !== '' &&
                  formik.values.contract_end_date !== undefined
                    ? formik.values.contract_end_date
                    : new Date()
                }
                selected={handleContractStartDate(formik.values.contract_start_date)}
                dateFormat={dateFormatCommon()}
                onChange={(date) => {
                  formik.setFieldValue('contract_start_date', date);
                  generateContractNumber(date);
                }}
                onSelect={(date) => {
                  const aYearFromNow = new Date(date);
                  formik.setFieldValue(
                    'contract_end_date',
                    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1)
                  );
                }}
                onBlur={(date) => {
                  var date1 = moment(date.currentTarget.value, 'DD-MM-YYYY');

                  if (date.currentTarget.value) {
                    if (!date1.isValid()) {
                      toast.error(t('page.invalid_date_format'));
                      formik.setFieldValue('contract_start_date', '');
                    } else {
                      const aYearFromNow = new Date(date1);
                      formik.setFieldValue(
                        'contract_end_date',
                        aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1)
                      );
                    }
                  } else {
                    formik.setFieldValue('contract_start_date', '');
                  }
                }}
              />
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.contract_start_date && formik.errors.contract_start_date
                    ? 'form-field-error'
                    : formik.touched.contract_start_date && !formik.errors.contract_start_date
                    ? 'form-field-success'
                    : '')
                }
                type="hidden"
                name="contract_start_date"
                placeholder={t(
                  'page.add_producer_contract_details_contract_start_date_placeholder'
                )}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.contract_start_date}
              />
              <div className="form-field-error-text">
                {formik.touched.contract_start_date && formik.errors.contract_start_date ? (
                  <div>{t(formik.errors.contract_start_date)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_producer_contract_details_contract_end_date_label')}
                <span className="preview-image">
                  {formik.values.contract_start_date !== '' &&
                  formik.values.contract_end_date !== '' &&
                  !disableContractDate ? (
                    <FontAwesomeIcon
                      icon={faClose}
                      onClick={() => {
                        formik.setFieldValue('contract_end_date', '');
                      }}
                    />
                  ) : (
                    ''
                  )}
                </span>
              </Form.Label>
              <DatePicker
                disabled={disableContractDate}
                onKeyDown={dateFormatManualAdd}
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
                adjustDateOnChange
                className="form-control filter-column"
                placeholderText={dateFormatCommon().toUpperCase()}
                selected={formik.values.contract_end_date}
                dateFormat={dateFormatCommon()}
                minDate={formik.values.contract_start_date}
                onChange={(date) => {
                  formik.setFieldValue('contract_end_date', date);
                }}
                onBlur={(date) => {
                  var date1 = moment(date.currentTarget.value, 'DD-MM-YYYY');
                  if (date.currentTarget.value) {
                    if (!date1.isValid()) {
                      toast.error(t('page.invalid_date_format'));
                      formik.setFieldValue('contract_end_date', '');
                    } else {
                      var minDate =
                        formik.values.contract_start_date !== '' &&
                        formik.values.contract_start_date !== undefined
                          ? formik.values.contract_start_date
                          : new Date();
                      if (date1 < minDate) {
                        toast.error(t('page.to_date_greater_than_from_Date'));
                        formik.setFieldValue('contract_end_date', '');
                      }
                    }
                  }
                }}
              />
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.contract_end_date && formik.errors.contract_end_date
                    ? 'form-field-error'
                    : formik.touched.contract_end_date && !formik.errors.contract_end_date
                    ? 'form-field-success'
                    : '')
                }
                type="hidden"
                name="contract_end_date"
                placeholder={t('page.add_producer_contract_details_contract_end_date_placeholder')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.contract_end_date}
              />
              <div className="form-field-error-text">
                {formik.touched.contract_end_date && formik.errors.contract_end_date ? (
                  <div>{t(formik.errors.contract_end_date)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.add_producer_contract_details_contract_agent_id_label')}
              </Form.Label>
              <Select
                isClearable={true}
                name="agent_id"
                options={agentOptions}
                placeholder={t('page.add_producer_contract_details_contract_agent_id_placeholder')}
                onChange={(selected) => {
                  if (selected !== null) {
                    formik.setFieldValue('agent_id', selected.value);
                  } else {
                    formik.setFieldValue('agent_id', '');
                  }
                }}
                defaultValue={formik.values.agent_id}
                value={defaultValue(agentOptions, formik.values.agent_id)}
                onBlur={formik.handleBlur}
                className={
                  'form-field ' +
                  (formik.touched.agent_id && formik.errors.agent_id
                    ? 'form-select-error'
                    : formik.touched.agent_id && !formik.errors.agent_id
                    ? 'form-select-success'
                    : '')
                }
              />
              <div className="form-field-error-text">
                {formik.touched.agent_id && formik.errors.agent_id ? (
                  <div>{t(formik.errors.agent_id)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.surat_keputusan')}
              </Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.surat_keputusan && formik.errors.surat_keputusan
                    ? 'form-field-error'
                    : formik.touched.surat_keputusan && !formik.errors.surat_keputusan
                    ? 'form-field-success'
                    : '')
                }
                type="text"
                name="surat_keputusan"
                placeholder={t('page.surat_keputusan_placeholder')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.surat_keputusan}
              />
              <div className="form-field-error-text">
                {formik.touched.surat_keputusan && formik.errors.surat_keputusan ? (
                  <div>{t(formik.errors.surat_keputusan)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">
                {t('page.nomor_surat_keputusan')}
              </Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.nomor_surat_keputusan && formik.errors.nomor_surat_keputusan
                    ? 'form-field-error'
                    : formik.touched.nomor_surat_keputusan && !formik.errors.nomor_surat_keputusan
                    ? 'form-field-success'
                    : '')
                }
                type="text"
                name="nomor_surat_keputusan"
                placeholder={t('page.nomor_surat_keputusan_placeholder')}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.nomor_surat_keputusan}
              />
              <div className="form-field-error-text">
                {formik.touched.nomor_surat_keputusan && formik.errors.nomor_surat_keputusan ? (
                  <div>{t(formik.errors.nomor_surat_keputusan)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={4}>
            <Form.Group>
              <Form.Label className="field-label field-label-top">Catatan Kontrak</Form.Label>
              <Form.Control
                className={
                  'form-field ' +
                  (formik.touched.contract_note && formik.errors.contract_note
                    ? 'form-field-error'
                    : formik.touched.contract_note && !formik.errors.contract_note
                    ? 'form-field-success'
                    : '')
                }
                type="text"
                name="contract_note"
                placeholder="Contoh: renew"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.contract_note}
              />
              <div className="form-field-error-text">
                {formik.touched.contract_note && formik.errors.contract_note ? (
                  <div>{t(formik.errors.contract_note)}</div>
                ) : null}
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={4}>
            <Form.Label className="field-label field-label-top">
              {t('page.add_producer_contact_details_contract_generate_mou_label')}
            </Form.Label>
            <br />
            <button
              type="button"
              className="btn btn-lg btn-outline-primary"
              disabled={loadingGenerateMOU || formik.values.is_collect_container === 1}
              onClick={() => {
                if (!formik.dirty) {
                  const check = formik.values;
                  if (
                    check.surat_keputusan &&
                    check.nomor_surat_keputusan &&
                    check.name &&
                    check.owner_first_name &&
                    check.owner_last_name &&
                    check.contract_start_date &&
                    check.contract_end_date &&
                    check.address
                  ) {
                    doGenerateMOU();
                  } else {
                    checkValueEmpty(
                      check.nomor_surat_keputusan,
                      'page.nomor_surat_keputusan_diperlukan'
                    );
                    checkValueEmpty(check.surat_keputusan, 'page.surat_keputusan_diperlukan');
                    checkValueEmpty(
                      check.name,
                      'page.add_producer_general_details_producer_name_validation_required'
                    );
                    checkValueEmpty(
                      check.owner_first_name,
                      'page.add_producer_owner_details_first_name_validation_required'
                    );
                    checkValueEmpty(
                      check.owner_last_name,
                      'page.add_producer_owner_details_last_name_validation_required'
                    );
                    checkValueEmpty(
                      check.contract_start_date,
                      'page.add_producer_contract_details_contract_start_date_validation_required'
                    );
                    checkValueEmpty(
                      check.contract_end_date,
                      'page.add_producer_contract_details_contract_end_date_validation_required'
                    );
                    checkValueEmpty(
                      check.address,
                      'page.add_producer_general_details_address_validation_required'
                    );
                  }
                } else toast.error(t('page.add_producer_contract_detail_must_save_first'));
              }}>
              {t('page.add_producer_contact_details_contract_generate_mou')}
            </button>
          </Col>
          {formik.values.mou_contract && (
            <Col sm={12} md={4}>
              <Form.Label className="field-label field-label-top">
                {t('page.add_producer_contact_details_contract_download_mou_label')}
              </Form.Label>
              <br />
              <a
                className="btn btn-lg btn-outline-secondary"
                href={formik.values.mou_contract + '?t=' + Date.now()}
                rel="noreferrer"
                target="_blank">
                {t('page.add_producer_contact_details_contract_download_mou')}
              </a>
            </Col>
          )}
          {formik.values.mou_status === 1 && formik.values.is_collect_container === 0 && (
            <Col sm={12} md={4}>
              <Form.Label className="field-label field-label-top">Resend MOU</Form.Label>
              <br />
              <button
                className="btn btn-lg btn-outline-primary"
                type="button"
                onClick={handleResendMou}>
                Resend MOU
              </button>
            </Col>
          )}
        </Row>
        <FieldArray
          name="contract_histories"
          render={(arrayHelpers) => (
            <div>
              <Row>
                <Col lg={4}>
                  <Form.Group className="row-top">
                    <Form.Label className="field-label field-label-top">Riwayat Kontrak</Form.Label>
                    <div />
                    <Form.Label
                      className="btn btn-primary fw-bolder"
                      htmlFor="contract-histories-file-input">
                      Upload
                    </Form.Label>
                    <div className="mb-4" />
                    <Form.Control
                      id="contract-histories-file-input"
                      type="file"
                      accept="image/*,.pdf"
                      hidden
                      onInput={(event) => {
                        const index = formik.values.contract_histories.length;
                        const name = `contract_histories.${index}.contract_histories`;

                        if (event.target.files.length > 0) {
                          arrayHelpers.push({
                            contract_histories_file: '',
                            contract_histories_name: '',
                            contract_histories_id: ''
                          });

                          const file = event.target.files[0];
                          formik.setFieldValue(name + '_file', file);
                          formik.setFieldValue(name + '_name', file.name);

                          // remove file after selection
                          // this useful if user select a file
                          // remove it and reselect the same file
                          event.target.value = '';
                        }
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {formik.values.contract_histories && formik.values.contract_histories.length > 0
                ? formik.values.contract_histories.map((contract_history, index) => {
                    const contract_histories_nameErrors =
                      (formik.errors.contract_histories?.length &&
                        formik.errors.contract_histories[index]?.contract_histories_name) ||
                      '';
                    const contract_histories_nameTouched =
                      (formik.touched.contract_histories?.length &&
                        formik.touched.contract_histories[index]?.contract_histories_name) ||
                      '';
                    const contract_histories_fileErrors =
                      (formik.errors.contract_histories?.length &&
                        formik.errors.contract_histories[index]?.contract_histories_file) ||
                      '';
                    const contract_histories_fileTouched =
                      (formik.touched.contract_histories?.length &&
                        formik.touched.contract_histories[index]?.contract_histories_file) ||
                      '';
                    return (
                      <div key={'contracts-' + index} className="mb-3">
                        <Row>
                          <Col lg={4} xs={12}>
                            <Field
                              type="hidden"
                              name={`contract_histories.${index}.contract_histories_id`}
                              value={contract_history.contract_histories_id}
                            />
                            <button
                              style={{
                                display: 'inline-block',
                                backgroundColor: '#015a06',
                                borderRadius: '100px',
                                border: 'none',
                                color: 'white',
                                textAlign: 'center',
                                width: '24px',
                                height: '24px',
                                marginInlineEnd: '1rem'
                              }}
                              onClick={(event) => {
                                handleDeleteClick(
                                  contract_history.contract_histories_id,
                                  `contract_histories.${index}.contract_histories`
                                );
                                // eslint-disable-next-line no-unused-expressions
                                contract_history.contract_histories_id === ''
                                  ? arrayHelpers.remove(index)
                                  : '';
                              }}>
                              <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                data-icon="xmark"
                                className="svg-inline--fa fa-xmark "
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 384 512">
                                <path
                                  fill="currentColor"
                                  d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"></path>
                              </svg>
                            </button>
                            {typeof contract_history.contract_histories_file === 'string' ? (
                              <a href={contract_history.contract_histories_file}>
                                {contract_history.contract_histories_name}
                              </a>
                            ) : (
                              contract_history.contract_histories_name
                            )}
                            <div className="form-field-error-text">
                              {contract_histories_nameTouched && contract_histories_nameErrors ? (
                                <div>{t(contract_histories_nameErrors)}</div>
                              ) : null}
                              {contract_histories_fileTouched && contract_histories_fileErrors ? (
                                <div>{t(contract_histories_fileErrors)}</div>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    );
                  })
                : ''}
            </div>
          )}
        />
      </Card.Body>
    </Card>
  );
};
ContractDetails.propTypes = {
  t: PropTypes.func
};
export { ContractDetails };
