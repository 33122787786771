import { TNButton } from '@common/components';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { imagePreviewFromik } from '@helpers/';
import { npwpFormat } from '@helpers/commonHelpers';
import {
  useDeleteProducerAttachment,
  useGetAreaData,
  useGetTerritoryData,
  useGetTransporterList,
  useProducerTypeWithTest,
  useGetProducerList
} from '@hooks';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Alert, Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert'; // Import
import Select from 'react-select';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import client from '@libs/HttpClient';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';

const PlaceAutoComplete = (props) => {
  const { t, formik, setAddress } = props;
  const [firstLoad, setFirstLoad] = useState(true);
  const {
    suggestions: { status, data },
    setValue
  } = usePlacesAutocomplete();

  const handleSelect = async (address) => {
    setValue(address, false);
    const results = await getGeocode({ address });
    setAddress(results[0]);
  };

  const getAddressList = async (address) => {
    await setValue(address);
  };

  useEffect(() => {
    setFirstLoad(false);
    if (!firstLoad) {
      if (status == 'OK') {
        handleSelect(data[0].description);
      } else {
        toast.error('No location found');
        formik.setFieldValue('address', '');
      }
    }
  }, [data]);

  return (
    <>
      <Form.Group>
        <Form.Label className="field-label field-label-top">
          {t('page.add_producer_general_details_address_label')}
        </Form.Label>
        <InputGroup className="inner-group-box">
          <Form.Control
            className={
              formik.touched.address && formik.errors.address
                ? 'form-field-error'
                : formik.touched.address && !formik.errors.address
                ? 'form-field-success'
                : ''
            }
            type="text"
            name="address"
            placeholder={t('page.add_producer_general_details_address_placeholder')}
            onKeyDown={(e) => {
              if (e.key !== 'Enter') return;
              e.preventDefault();
              formik.setFieldValue('address', 'Searching...');
              formik.setFieldValue('city', '');
              formik.setFieldValue('state', '');
              formik.setFieldValue('postcode', '');
              formik.setFieldValue('country', '');
              formik.setFieldValue('latitude', '');
              formik.setFieldValue('longitude', '');
              getAddressList(formik.values.address);
            }}
            onChange={(event) => {
              if (event.currentTarget.value === '') {
                formik.setFieldValue('address', '');
                formik.setFieldValue('city', '');
                formik.setFieldValue('state', '');
                formik.setFieldValue('postcode', '');
                formik.setFieldValue('country', '');
                formik.setFieldValue('latitude', '');
                formik.setFieldValue('longitude', '');
              } else {
                formik.setFieldValue('address', event.currentTarget.value);
              }
            }}
            value={formik.values.address}
            onBlur={formik.handleBlur}
          />
          <TNButton
            style={{ zIndex: 0 }}
            onClick={() => {
              formik.setFieldValue('address', 'Searching...');
              formik.setFieldValue('city', '');
              formik.setFieldValue('state', '');
              formik.setFieldValue('postcode', '');
              formik.setFieldValue('country', '');
              formik.setFieldValue('latitude', '');
              formik.setFieldValue('longitude', '');
              getAddressList(formik.values.address);
            }}
            type="button">
            {t('page.show_address_results')}
          </TNButton>
        </InputGroup>
        <div className="form-field-error-text">
          {formik.touched.address && formik.errors.address ? (
            <div>{t(formik.errors.address)}</div>
          ) : null}
        </div>
      </Form.Group>
      <div className="map-areas">
        <Row>
          <Col lg={4} sm={12} className={formik.values.city ? '' : 'd-none'}>
            {formik.values.city ? (
              <Alert variant="dark" className="m-1 p-1" style={{ fontSize: '12px' }}>
                Kecamatan: {formik.values.district}
              </Alert>
            ) : (
              ''
            )}
          </Col>
          <Col lg={4} sm={12} className={formik.values.city ? '' : 'd-none'}>
            {formik.values.city ? (
              <Alert variant="dark" className="m-1 p-1" style={{ fontSize: '12px' }}>
                Kabupaten/Kota: {formik.values.city}
              </Alert>
            ) : (
              ''
            )}
          </Col>
          <Col lg={4} sm={12} className={formik.values.state ? '' : 'd-none'}>
            {formik.values.state ? (
              <Alert variant="dark" className="m-1 p-1" style={{ fontSize: '12px' }}>
                Provinsi: {formik.values.state}
              </Alert>
            ) : (
              ''
            )}
          </Col>
          <Col lg={4} sm={12} className={formik.values.country ? '' : 'd-none'}>
            {formik.values.country ? (
              <Alert variant="dark" className="m-1 p-1" style={{ fontSize: '12px' }}>
                Negara: {formik.values.country}
              </Alert>
            ) : (
              ''
            )}
          </Col>
          <Col lg={4} sm={12} className={formik.values.postcode ? '' : 'd-none'}>
            {formik.values.postcode ? (
              <Alert variant="dark" className="m-1 p-1" style={{ fontSize: '12px' }}>
                Kode Pos: {formik.values.postcode}
              </Alert>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

const GeneralDetails = (props) => {
  const areaId = useRef();
  const formik = useFormikContext();
  let LogoRef = useRef();
  const defaultValue = (option, value) => {
    return option ? option.find((option) => option.value === value) : '';
  };
  const { t, countryIsLoad, countryCodeOptions } = props;

  const [ProducerCenter, setProducerCenter] = useState({
    lat: Number(formik.values.latitude) ?? -8.675835899999997,
    lng: Number(formik.values.longitude) ?? 115.2127549
  });
  const [ProducerZoom, setProducerZoom] = useState(18);
  const [DeleteAttchmentId, setDeleteAttchmentId] = useState(false);
  const containerStyle = {
    width: '100%',
    height: '300px'
  };

  let businessTypeOption = [
    { value: 'personal', label: 'Personal' },
    { value: 'corporate', label: 'Business/Corporate' }
  ];

  useEffect(() => {
    setProducerCenter({
      lat: Number(formik.values.latitude),
      lng: Number(formik.values.longitude)
    });
  }, [formik.values.latitude, formik.values.longitude]);

  /**
   * !This block is for google address
   */
  const setAddress = (address) => {
    const place = address;
    const { lat, lng } = getLatLng(place);

    let address1 = place.formatted_address;
    let postcode = '';
    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        // case 'street_number': {
        //   address1 = `${component.long_name} ${address1}`;
        //   break;
        // }

        // case 'route': {
        //   address1 += component.short_name;
        //   break;
        // }

        case 'postal_code': {
          postcode = `${component.long_name}${postcode}`;
          break;
        }

        case 'postal_code_suffix': {
          postcode = `${postcode}-${component.long_name}`;
          break;
        }

        case 'locality':
          formik.setFieldValue('city', component.long_name);
          break;

        case 'administrative_area_level_1': {
          formik.setFieldValue('state', component.long_name);
          break;
        }

        case 'administrative_area_level_2': {
          formik.setFieldValue('city', component.long_name);
          break;
        }

        case 'administrative_area_level_3': {
          formik.setFieldValue('district', component.long_name);
          break;
        }

        case 'country':
          formik.setFieldValue('country', component.long_name);
          break;

        default:
          break;
      }
    }
    formik.setFieldValue('address', address1);
    formik.setFieldValue('postcode', postcode);
    formik.setFieldValue('latitude', lat);
    formik.setFieldValue('longitude', lng);
    setProducerCenter({
      lat: lat,
      lng: lng
    });
    setProducerZoom(18);
  };

  /**
   * !This Block is for Delete Attachment
   */
  const handleDeleteClick = (tdata) => {
    let attchament_id = tdata.currentTarget.getAttribute('attchament_id');
    let field_name = tdata.currentTarget.getAttribute('field_name');
    if (attchament_id !== '') {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h2>{t('page.delete_attachment_popup_message')}</h2>
                <Button
                  className="table-delete-button"
                  onClick={() => {
                    formik.setFieldValue(field_name, '');
                    formik.setFieldValue(`${field_name}_id`, '');
                    setDeleteAttchmentId(attchament_id);

                    onClose();
                  }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        }
      });
    } else {
      formik.setFieldValue(field_name, '');
      formik.setFieldValue(`${field_name}_id`, '');
    }
  };

  /**
   * !This Block will call delete attachment api
   */
  useDeleteProducerAttachment(
    [DeleteAttchmentId, 'logo'],
    (res) => {
      toast.success(res.message);
      setDeleteAttchmentId(false);
    },
    (err) => {
      toast.error(err.message);
      setDeleteAttchmentId(false);
    }
  );

  /**
   * !This block is for contact status drop down
   */
  const StatusOption = [
    { value: '1', label: t('page.contract_status_option_pending') },
    { value: '2', label: t('page.contract_status_option_inreview') },
    { value: '3', label: t('page.contract_status_option_active') },
    { value: '4', label: t('page.contract_status_option_hold'), isDisabled: true },
    { value: '5', label: t('page.contract_status_option_suspended') },
    { value: '6', label: t('page.contract_status_option_terminated') }
  ];

  let ProducerTypeOptions = [];
  /**
   * !This Api is for Producer type drop down
   */
  const { isSuccess: ProducerTypeisSuccess, data: ProducerTypeList } = useProducerTypeWithTest();
  if (ProducerTypeisSuccess !== false) {
    ProducerTypeOptions = ProducerTypeList.data.map((item, i) => {
      return { value: item.producer_type_id, label: item.name };
    });
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script'
  });
  // const [map, setMap] = useState(null);

  let ProducerSubTypeOptions = useRef([]);

  useEffect(() => {
    async function getProducerSubType() {
      const resp = await client.get(
        `/v2/sub-producer-type/producer-type/${formik.values.producer_type_id}`
      );
      ProducerSubTypeOptions.current = resp.data.map((item, i) => {
        return { value: item.id, label: item.type_name };
      });
    }
    if (formik.values.producer_type_id) {
      getProducerSubType();
    }
  }, [formik.values.producer_type_id]);

  const onLoad = useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    // setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    // setMap(null);
  }, []);

  const onLoadmarker = (marker) => {};

  const getAddressFromLatLng = async (lat, lng) => {
    try {
      const res = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&language=id&key=${process.env.MIX_GOOGLE_MAPS_API_KEY}`
      );
      const json = await res.json();

      for (const component of json.results[0].address_components) {
        const componentType = component.types[0];
        switch (componentType) {
          case 'postal_code': {
            formik.setFieldValue('postcode', component.long_name);
            break;
          }

          case 'administrative_area_level_1': {
            formik.setFieldValue('state', component.long_name);
            break;
          }

          case 'administrative_area_level_2': {
            formik.setFieldValue('city', component.long_name);
            break;
          }

          case 'administrative_area_level_3': {
            formik.setFieldValue('district', component.long_name);
            break;
          }

          case 'country':
            formik.setFieldValue('country', component.long_name);
            break;

          default:
            break;
        }
      }

      return json.results[0].formatted_address;
    } catch (err) {
      console.error(err);
    }
  };

  const handleChangeIsBuyContainer = (checked) => {
    if (checked === true) {
      formik.setFieldValue('is_buy_container', 1);
    } else {
      formik.setFieldValue('is_buy_container', 0);
    }
  };

  const handleChangeIsCollectContainer = (checked) => {
    if (checked === true) {
      formik.setFieldValue('is_collect_container', 1);
    } else {
      formik.setFieldValue('is_collect_container', 0);
    }
  };

  const handleChangeManualMou = (checked) => {
    if (checked === true) {
      formik.setFieldValue('mou_status', 0);
    } else {
      formik.setFieldValue('mou_status', 2);
    }
  };

  /**
   * !This Api is for territory drop down
   */
  let TerritoryOptions = [];
  const { isSuccess: TerritoryisSuccess, data: TerritoryList } = useGetTerritoryData();
  if (TerritoryisSuccess !== false) {
    TerritoryOptions = TerritoryList.data.map((item, i) => {
      return { value: item.territory_id, label: item.name };
    });
  }

  /**
   * !This Api is for area drop down
   */
  let AreaOptions = [];
  const { isSuccess: AreaisSuccess, data: AreaList } = useGetAreaData(formik.values?.territory_id);
  if (AreaisSuccess !== false) {
    AreaOptions = AreaList.data.map((item, i) => {
      return { value: item.area_id, label: item.area_name };
    });
  }

  const { isLoading: isLoadingTransporters, data: transporters } = useGetTransporterList();
  let transporterOptions = [];
  if (transporters !== undefined) {
    transporterOptions = transporters.data.map((item) => {
      return { value: item.transporter_id, label: item.name };
    });
  }

  const { isLoading: isLoadingReferrer, data: referrers } = useGetProducerList();
  let referrerOptions = [];
  if (referrers !== undefined) {
    referrerOptions = referrers.data.map((item) => {
      return { value: item.producer_id, label: item.name };
    });
  }

  return (
    <>
      <Card>
        <Card.Body>
          <Row>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label className="field-label field-label-top">
                  {t('page.add_producer_general_details_producer_name_label')}
                </Form.Label>
                <Form.Control
                  className={
                    'form-field ' +
                    (formik.touched.name && formik.errors.name
                      ? 'form-field-error'
                      : formik.touched.name && !formik.errors.name
                      ? 'form-field-success'
                      : '')
                  }
                  type="text"
                  name="name"
                  placeholder={t('page.add_producer_general_details_producer_name_placeholder')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                <div className="form-field-error-text">
                  {formik.touched.name && formik.errors.name ? (
                    <div>{t(formik.errors.name)}</div>
                  ) : null}
                </div>
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Row>
                <Col lg={6} sm={12}>
                  <Form.Label className="field-label field-label-top">
                    {t('page.add_producer_general_details_producer_logo_label')}
                  </Form.Label>
                  <Form.Control
                    className={
                      'form-field ' +
                      (formik.touched.logo && formik.errors.logo
                        ? 'form-field-error'
                        : formik.touched.logo && !formik.errors.logo
                        ? 'form-field-success'
                        : '')
                    }
                    type="file"
                    hidden
                    name="logo"
                    accept="image/*"
                    placeholder={t('page.add_producer_general_details_producer_logo_placeholder')}
                    onChange={(event) => {
                      formik.setFieldValue('logo', event.currentTarget.files[0]);
                    }}
                    ref={LogoRef}
                  />
                  <br />
                  <Button
                    type="button"
                    onClick={() => {
                      LogoRef.current?.click();
                    }}
                    className={
                      'upload-button m-0' ||
                      (formik.touched.logo && formik.errors.logo
                        ? 'form-field-error'
                        : formik.touched.logo && !formik.errors.logo
                        ? 'form-field-success'
                        : '')
                    }>
                    {t('page.file_upload')}
                  </Button>
                </Col>
                <Col lg={6} sm={12}>
                  {formik.values.logo && (
                    <div className="preview-image">
                      <img src={imagePreviewFromik(formik.values.logo)} alt="profile_img" />
                      {formik.values.logo_id !== '' ? (
                        <FontAwesomeIcon
                          icon={faClose}
                          attchament_id={formik.values.logo_id}
                          field_name={'logo'}
                          onClick={(event) => {
                            handleDeleteClick(event);
                            LogoRef.current.value = null;
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faClose}
                          onClick={() => {
                            LogoRef.current.value = null;
                            formik.setFieldValue('logo', '');
                          }}
                        />
                      )}
                    </div>
                  )}
                </Col>
                <div className="form-field-error-text">
                  {formik.touched.logo && formik.errors.logo ? (
                    <div>{t(formik.errors.logo)}</div>
                  ) : null}
                </div>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label className="field-label field-label-top">
                  {t('page.add_producer_general_details_business_type_label')}
                </Form.Label>
                <Select
                  name="business_type"
                  options={businessTypeOption}
                  placeholder={t('page.add_producer_general_details_business_type_placeholder')}
                  onChange={(selected) => {
                    if (selected !== null) {
                      formik.setFieldValue('business_type', selected.value);
                    } else {
                      formik.setFieldValue('business_type', '');
                    }
                  }}
                  defaultValue={formik.values.business_type}
                  value={defaultValue(businessTypeOption, formik.values.business_type)}
                  onBlur={formik.handleBlur}
                  className={
                    'form-field ' +
                    (formik.touched.business_type && formik.errors.business_type
                      ? 'form-select-error'
                      : formik.touched.business_type && !formik.errors.business_type
                      ? 'form-select-success'
                      : '')
                  }
                />
                <div className="form-field-error-text">
                  {formik.touched.business_type && formik.errors.business_type ? (
                    <div>{t(formik.errors.business_type)}</div>
                  ) : null}
                </div>
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label className="field-label field-label-top">
                  {t('page.add_producer_general_details_npwp_number_label')}
                </Form.Label>
                <Form.Control
                  className={
                    'form-field ' +
                    (formik.touched.producer_npwp_number && formik.errors.producer_npwp_number
                      ? 'form-field-error'
                      : formik.touched.producer_npwp_number && !formik.errors.producer_npwp_number
                      ? 'form-field-success'
                      : '')
                  }
                  type="text"
                  name="producer_npwp_number"
                  placeholder={t('page.add_producer_general_details_npwp_number_placeholder')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.producer_npwp_number}
                  maxLength={20}
                  //onKeyUp={npwpFormat}
                />
                <div className="form-field-error-text">
                  {formik.touched.producer_npwp_number && formik.errors.producer_npwp_number ? (
                    <div>{t(formik.errors.producer_npwp_number)}</div>
                  ) : null}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label className="field-label field-label-top">
                  {t('page.simpel_id')}
                </Form.Label>
                <Form.Control
                  className={
                    'form-field ' +
                    (formik.touched.simpel_id && formik.errors.simpel_id
                      ? 'form-field-error'
                      : formik.touched.simpel_id && !formik.errors.simpel_id
                      ? 'form-field-success'
                      : '')
                  }
                  type="text"
                  name="simpel_id"
                  placeholder={t('page.simpel_id_placeholder')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.simpel_id}
                />
                <div className="form-field-error-text">
                  {formik.touched.simpel_id && formik.errors.simpel_id ? (
                    <div>{t(formik.errors.simpel_id)}</div>
                  ) : null}
                </div>
              </Form.Group>
            </Col>
            <Col sm={12} md={6}>
              <Form.Group>
                <Form.Label className="field-label field-label-top">
                  {t('page.simpel_registration_number')}
                </Form.Label>
                <Form.Control
                  className={
                    'form-field ' +
                    (formik.touched.simpel_registration_number &&
                    formik.errors.simpel_registration_number
                      ? 'form-field-error'
                      : formik.touched.simpel_registration_number &&
                        !formik.errors.simpel_registration_number
                      ? 'form-field-success'
                      : '')
                  }
                  type="text"
                  name="simpel_registration_number"
                  placeholder={t('page.simpel_registration_number_placeholder')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.simpel_registration_number}
                />
                <div className="form-field-error-text">
                  {formik.touched.simpel_registration_number &&
                  formik.errors.simpel_registration_number ? (
                    <div>{t(formik.errors.simpel_registration_number)}</div>
                  ) : null}
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={4}>
              <Form.Label className="field-label field-label-top">
                {t('page.add_producer_general_details_contact_number_label')}
              </Form.Label>
              <Row>
                <Col lg={6} xs={6}>
                  <Select
                    name="country_id"
                    placeholder={t('page.add_producer_general_details_country_id_placeholder')}
                    value={defaultValue(countryCodeOptions, formik.values.country_id)}
                    defaultValue={formik.values.country_id}
                    onChange={(selectedOption) => {
                      if (selectedOption !== null) {
                        formik.setFieldValue('country_id', selectedOption.value);
                      } else {
                        formik.setFieldValue('country_id', '');
                      }
                    }}
                    className={
                      'contry-code ' +
                      (formik.touched.country_id && formik.errors.country_id
                        ? 'form-select-error'
                        : formik.touched.country_id && !formik.errors.country_id
                        ? 'form-select-success'
                        : '')
                    }
                    options={countryIsLoad ? countryCodeOptions : countryCodeOptions}
                  />
                  <div className="form-field-error-text">
                    {formik.touched.country_id && formik.errors.country_id ? (
                      <div>{t(formik.errors.country_id)}</div>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6} xs={6} className="px-0">
                  <Form.Control
                    className={
                      'contact-number ' +
                      (formik.touched.phone && formik.errors.phone
                        ? 'form-field-error'
                        : formik.touched.phone && !formik.errors.phone
                        ? 'form-field-success'
                        : '')
                    }
                    type="text"
                    name="phone"
                    placeholder={t('page.add_producer_general_details_contact_number_placeholder')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                  />
                  <div className="form-field-error-text">
                    {formik.touched.phone && formik.errors.phone ? (
                      <div>{t(formik.errors.phone)}</div>
                    ) : null}
                  </div>
                </Col>
              </Row>

              <Form.Group>
                <Form.Label className="field-label field-label-top">
                  {t('page.add_producer_general_details_producer_type_label')}
                </Form.Label>
                <Select
                  isClearable={true}
                  name="producer_type_id"
                  options={ProducerTypeisSuccess ? ProducerTypeOptions : ProducerTypeOptions}
                  placeholder={t('page.add_producer_general_details_producer_type_placeholder')}
                  onChange={(selected) => {
                    if (selected !== null) {
                      formik.setFieldValue('producer_type_id', selected.value);
                      let ProducerTypeOptionsisLicense = ProducerTypeList.data.filter((item, i) => {
                        return selected.value === item.producer_type_id && item.is_license === 1;
                      });
                      formik.setFieldValue(
                        'is_licence',
                        ProducerTypeOptionsisLicense.length > 0 ? true : false
                      );
                    } else {
                      formik.setFieldValue('producer_type_id', '');
                      formik.setFieldValue('sub_producer_type_id', '');
                      formik.setFieldValue('is_licence', false);
                    }
                  }}
                  value={defaultValue(ProducerTypeOptions, formik.values?.producer_type_id)}
                  defaultValue={formik.value?.producer_type_id}
                  onBlur={formik.handleBlur}
                  className={
                    'form-field ' +
                    (formik.touched.producer_type_id && formik.errors.producer_type_id
                      ? 'form-select-error'
                      : formik.touched.producer_type_id && !formik.errors.producer_type_id
                      ? 'form-select-success'
                      : '')
                  }
                />
                <div className="form-field-error-text">
                  {formik.touched.producer_type_id && formik.errors.producer_type_id ? (
                    <div>{t(formik.errors.producer_type_id)}</div>
                  ) : null}
                </div>
                <Form.Control
                  value={formik.values.is_licence}
                  type="hidden"
                  name="is_licence"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Form.Group>

              {formik.values.producer_type_id && (
                <Form.Group>
                  <Form.Label className="field-label field-label-top">
                    {t('page.add_producer_general_details_sub_producer_type_label')}
                  </Form.Label>
                  <Select
                    isClearable={true}
                    name="producer_type_id"
                    options={ProducerSubTypeOptions.current}
                    placeholder={t(
                      'page.add_producer_general_details_sub_producer_type_placeholder'
                    )}
                    onChange={(selected) => {
                      if (selected !== null) {
                        formik.setFieldValue('sub_producer_type_id', selected.value);
                      } else {
                        formik.setFieldValue('sub_producer_type_id', '');
                      }
                    }}
                    value={defaultValue(
                      ProducerSubTypeOptions.current,
                      formik.values?.sub_producer_type_id
                    )}
                    defaultValue={formik.value?.sub_producer_type_id}
                    onBlur={formik.handleBlur}
                    className={
                      'form-field ' +
                      (formik.touched.sub_producer_type_id && formik.errors.sub_producer_type_id
                        ? 'form-select-error'
                        : formik.touched.sub_producer_type_id && !formik.errors.sub_producer_type_id
                        ? 'form-select-success'
                        : '')
                    }
                  />
                  <div className="form-field-error-text">
                    {formik.touched.sub_producer_type_id && formik.errors.sub_producer_type_id ? (
                      <div>{t(formik.errors.sub_producer_type_id)}</div>
                    ) : null}
                  </div>
                </Form.Group>
              )}

              {formik.values.is_licence === true ? (
                <Form.Group>
                  <Form.Label className="field-label field-label-top">
                    {t('page.add_producer_general_details_licence_number_label')}
                  </Form.Label>
                  <Form.Control
                    disabled={formik.values.is_licence === true ? false : true}
                    className={
                      'form-field ' +
                      (formik.touched.licence_number && formik.errors.licence_number
                        ? 'form-field-error'
                        : formik.touched.licence_number && !formik.errors.licence_number
                        ? 'form-field-success'
                        : '')
                    }
                    type="text"
                    name="licence_number"
                    placeholder={t('page.add_producer_general_details_licence_number_placeholder')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.licence_number}
                  />
                  <div className="form-field-error-text">
                    {formik.touched.licence_number && formik.errors.licence_number ? (
                      <div>{t(formik.errors.licence_number)}</div>
                    ) : null}
                  </div>
                </Form.Group>
              ) : null}

              <Form.Group>
                <Form.Label className="field-label field-label-top">
                  {t('page.add_producer_general_details_status_label')}
                </Form.Label>
                <Select
                  isClearable={true}
                  name="contract_status"
                  options={StatusOption}
                  placeholder={t('page.add_producer_general_details_status_placeholder')}
                  onChange={(selected) => {
                    if (selected !== null) {
                      formik.setFieldValue('contract_status', selected.value);
                    } else {
                      formik.setFieldValue('contract_status', '');
                    }
                  }}
                  defaultValue={formik.values.contract_status}
                  value={defaultValue(StatusOption, formik.values.contract_status)}
                  onBlur={formik.handleBlur}
                  className={
                    'form-field ' +
                    (formik.touched.contract_status && formik.errors.contract_status
                      ? 'form-select-error'
                      : formik.touched.contract_status && !formik.errors.contract_status
                      ? 'form-select-success'
                      : '')
                  }
                />
                <div className="form-field-error-text">
                  {formik.touched.contract_status && formik.errors.contract_status ? (
                    <div>{t(formik.errors.contract_status)}</div>
                  ) : null}
                </div>
              </Form.Group>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.add_producer_is_buy_container_label')}
                    </Form.Label>
                    <br />
                    <Switch
                      onChange={handleChangeIsBuyContainer}
                      checked={formik.values.is_buy_container === 1}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label className="field-label field-label-top">
                      {t('page.add_producer_is_collect_container_label')}
                    </Form.Label>
                    <br />
                    <Switch
                      disabled={formik.values.mou_status === 1}
                      onChange={handleChangeIsCollectContainer}
                      checked={formik.values.is_collect_container === 1}
                    />
                    {formik.values.mou_status === 1 && (
                      <div style={{ color: 'red' }}>
                        Sedang dalam proses TTD Mou, Collect akan berubah otomatis
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group>
                <Form.Label className="field-label field-label-top">
                  {t('page.auto_mou')}
                </Form.Label>
                <br />
                <Switch
                  onChange={handleChangeManualMou}
                  checked={formik.values.mou_status === 0 || formik.values.mou_status === 1}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label className="field-label field-label-top">
                  {t('page.add_producer_collect_details_territory_name_label')}
                </Form.Label>
                <Select
                  isClearable={true}
                  name="territory_id"
                  options={TerritoryisSuccess ? TerritoryOptions : TerritoryOptions}
                  placeholder={t('page.add_producer_collect_details_territory_name_placeholder')}
                  onChange={(selected) => {
                    if (selected !== null) {
                      formik.setFieldValue('territory_id', selected.value);
                    } else {
                      formik.setFieldValue('territory_id', '');
                    }
                    formik.setFieldValue('area_id', '');
                    areaId.current.clearValue();
                  }}
                  defaultValue={formik.values.territory_id}
                  value={defaultValue(TerritoryOptions, formik.values.territory_id)}
                  onBlur={formik.handleBlur}
                  className={
                    'form-field ' +
                    (formik.touched.territory_id && formik.errors.territory_id
                      ? 'form-select-error'
                      : formik.touched.territory_id && !formik.errors.territory_id
                      ? 'form-select-success'
                      : '')
                  }
                />
                <div className="form-field-error-text">
                  {formik.touched.territory_id && formik.errors.territory_id ? (
                    <div>{t(formik.errors.territory_id)}</div>
                  ) : null}
                </div>
              </Form.Group>

              <Form.Group>
                <Form.Label className="field-label field-label-top">
                  {t('page.add_producer_collect_details_area_name_label')}
                </Form.Label>
                <Select
                  ref={areaId}
                  isClearable={true}
                  name="area_id"
                  options={AreaisSuccess ? AreaOptions : AreaOptions}
                  placeholder={t('page.add_producer_collect_details_area_name_placeholder')}
                  onChange={(selected) => {
                    if (selected !== null) {
                      formik.setFieldValue('area_id', selected.value);
                    } else {
                      formik.setFieldValue('area_id', '');
                    }
                  }}
                  value={defaultValue(AreaOptions, formik.values.area_id)}
                  defaultValue={formik.value?.area_id}
                  onBlur={formik.handleBlur}
                  className={
                    'mb-3 form-field ' +
                    (formik.touched.area_id && formik.errors.area_id
                      ? 'form-select-error'
                      : formik.touched.area_id && !formik.errors.area_id
                      ? 'form-select-success'
                      : '')
                  }
                />
                <div className="form-field-error-text">
                  {formik.touched.area_id && formik.errors.area_id ? (
                    <div>{t(formik.errors.area_id)}</div>
                  ) : null}
                </div>
              </Form.Group>

              <Form.Group>
                <Form.Label className="field-label">{t('Transporter')}</Form.Label>
                <Select
                  isLoading={isLoadingTransporters}
                  isClearable={true}
                  name="default_transporter"
                  options={transporterOptions}
                  placeholder={t('Pilih Transporter')}
                  onChange={(selected) => {
                    if (selected !== null) {
                      formik.setFieldValue('default_transporter', selected.value);
                    } else {
                      formik.setFieldValue('default_transporter', '');
                    }
                  }}
                  value={defaultValue(transporterOptions, formik.values.default_transporter)}
                  defaultValue={formik.value?.default_transporter}
                  onBlur={formik.handleBlur}
                  className={
                    'mb-3 form-field ' +
                    (formik.touched.default_transporter && formik.errors.default_transporter
                      ? 'form-select-error'
                      : formik.touched.default_transporter && !formik.errors.default_transporter
                      ? 'form-select-success'
                      : '')
                  }
                />
                <div className="form-field-error-text">
                  {formik.touched.default_transporter && formik.errors.default_transporter ? (
                    <div>{t(formik.errors.default_transporter)}</div>
                  ) : null}
                </div>
              </Form.Group>

              <Form.Group>
                <Form.Label className="field-label">{t('Referrer')}</Form.Label>
                <Select
                  isLoading={isLoadingReferrer}
                  isClearable={true}
                  name="referred_by"
                  options={referrerOptions}
                  placeholder={t('Pilih Perujuk')}
                  onChange={(selected) => {
                    if (selected !== null) {
                      formik.setFieldValue('referred_by', selected.value);
                    } else {
                      formik.setFieldValue('referred_by', '');
                    }
                  }}
                  value={defaultValue(referrerOptions, formik.values.referred_by)}
                  defaultValue={formik.value?.referred_by}
                  onBlur={formik.handleBlur}
                  className={
                    'mb-3 form-field ' +
                    (formik.touched.referred_by && formik.errors.referred_by
                      ? 'form-select-error'
                      : formik.touched.referred_by && !formik.errors.referred_by
                      ? 'form-select-success'
                      : '')
                  }
                />
                <div className="form-field-error-text">
                  {formik.touched.referred_by && formik.errors.referred_by ? (
                    <div>{t(formik.errors.referred_by)}</div>
                  ) : null}
                </div>
              </Form.Group>
            </Col>
            <Col sm={12} md={8}>
              {isLoaded && (
                <>
                  <PlaceAutoComplete t={t} formik={formik} setAddress={setAddress} />
                  <div className="mt-2">
                    {ProducerCenter ? (
                      <>
                        <GoogleMap
                          mapContainerStyle={containerStyle}
                          center={ProducerCenter}
                          zoom={ProducerZoom}
                          onLoad={onLoad}
                          onPlaceSelected={(event) => {}}
                          onUnmount={onUnmount}>
                          <Marker
                            onLoad={onLoadmarker}
                            position={ProducerCenter}
                            draggable={true}
                            onPositionChanged={(e) => {}}
                            onDragEnd={async (e) => {
                              formik.setFieldValue('latitude', e.latLng.lat());
                              formik.setFieldValue('longitude', e.latLng.lng());
                              setProducerCenter({
                                lat: e.latLng.lat(),
                                lng: e.latLng.lng()
                              });

                              const results = await getAddressFromLatLng(
                                e.latLng.lat(),
                                e.latLng.lng()
                              );
                              formik.setFieldValue('address', results);
                            }}
                          />
                        </GoogleMap>
                        <Form.Group>
                          <Form.Label className="field-label field-label-top">
                            {t('page.notes_label')}
                          </Form.Label>
                          <Form.Control
                            className={
                              'address_notes ' +
                              (formik.touched.address_notes && formik.errors.address_notes
                                ? 'form-field-error'
                                : formik.touched.address_notes && !formik.errors.address_notes
                                ? 'form-field-success'
                                : '')
                            }
                            as="textarea"
                            rows={8}
                            type="text"
                            name="address_notes"
                            placeholder={t('page.notes_placeholder')}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.address_notes}
                          />
                          <div className="form-field-error-text">
                            {formik.touched.address_notes && formik.errors.address_notes ? (
                              <div>{t(formik.errors.address_notes)}</div>
                            ) : null}
                          </div>
                        </Form.Group>
                      </>
                    ) : null}
                  </div>
                </>
              )}
            </Col>
          </Row>
          {/* Section Invoice Address */}
          <Row className="my-2">
            <Col sm={12} md={4}>
              <Form.Group>
                <Form.Label className="field-label field-label-top">Legal Name</Form.Label>
                <Form.Control
                  className={
                    'form-field ' +
                    (formik.touched.invoice_name && formik.errors.invoice_name
                      ? 'form-field-error'
                      : formik.touched.invoice_name && !formik.errors.invoice_name
                      ? 'form-field-success'
                      : '')
                  }
                  type="text"
                  name="invoice_name"
                  placeholder="PT. ..."
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.invoice_name ?? ''}
                />
                <div className="form-field-error-text">
                  {formik.touched.invoice_name && formik.errors.invoice_name ? (
                    <div>{t(formik.errors.invoice_name)}</div>
                  ) : null}
                </div>
              </Form.Group>
            </Col>
            <Col sm={12} md={4}>
              <Form.Group>
                <Form.Label className="field-label field-label-top">Legal NPWP</Form.Label>
                <Form.Control
                  className={
                    'form-field ' +
                    (formik.touched.invoice_npwp && formik.errors.invoice_npwp
                      ? 'form-field-error'
                      : formik.touched.invoice_npwp && !formik.errors.invoice_npwp
                      ? 'form-field-success'
                      : '')
                  }
                  type="text"
                  name="invoice_npwp"
                  placeholder="NPWP Perusahaan Invoice"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.invoice_npwp ?? ''}
                  //onKeyUp={npwpFormat}
                  maxLength={20}
                />
                <div className="form-field-error-text">
                  {formik.touched.invoice_npwp && formik.errors.invoice_npwp ? (
                    <div>{t(formik.errors.invoice_npwp)}</div>
                  ) : null}
                </div>
              </Form.Group>
            </Col>
            <Col sm={12} md={4}>
              <Form.Group>
                <Form.Label className="field-label field-label-top">Legal Address</Form.Label>
                <Form.Control
                  className={
                    'form-field ' +
                    (formik.touched.invoice_address && formik.errors.invoice_address
                      ? 'form-field-error'
                      : formik.touched.invoice_address && !formik.errors.invoice_address
                      ? 'form-field-success'
                      : '')
                  }
                  type="text"
                  name="invoice_address"
                  placeholder="Alamat Perusahaan Invoice"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.invoice_address ?? ''}
                />
                <div className="form-field-error-text">
                  {formik.touched.invoice_address && formik.errors.invoice_address ? (
                    <div>{t(formik.errors.invoice_address)}</div>
                  ) : null}
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
GeneralDetails.propTypes = {
  t: PropTypes.func,
  countryCodeOptions: PropTypes.any,
  countryIsLoad: PropTypes.bool
};
export { GeneralDetails };
