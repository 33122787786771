import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Accordion, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

import {
  useGetCountryCodeList,
  useUpdateProducer,
  useViewProducer,
  useNavigatingAway,
  useContainerListData
} from '@hooks';
import AlertCommon from '@common/components/AlertCommon';
import { validationSchema } from './AddProducerValidation';
import {
  AccountingPersonDetails,
  EditAttachmentFiles,
  ContractDetails,
  GeneralDetails,
  OwnerDetails,
  PersonInCharge,
  EditWorkingDetails,
  CollectDetails
} from './components';
import { TNButton } from '@common/components/';
import TNBreadCurm from '@common/components/TNBreadCurm';
import moment from 'moment';
import { DialogLeavingPage } from '@common/components/AlertClosePage';
import { TabNavigation } from './components/TabNavigation';

const EditProducerPage = ({ t }) => {
  // From this api we are getting country code list.
  const navigate = useNavigate();
  let { producer_id } = useParams();
  const [formState, setFormState] = useState(false);
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] = useNavigatingAway(formState);
  const [accrdKey, setAccrdKey] = useState(0);
  const [copyData, setCopyData] = useState({
    country_id: '',
    phone: '',
    email: '',
    ktp: '',
    first_name: '',
    last_name: ''
  });
  const [sectionCopy, setSectionCopy] = useState(null);
  /**
   * !This Api is for country code drop down
   */
  const { isLoading: countryIsLoad, data: countryCodeList } = useGetCountryCodeList();
  let countryCodeOptions = [];
  if (countryCodeList !== undefined) {
    countryCodeOptions = countryCodeList.data.map((item, i) => {
      return { value: item.country_id, label: item.phone_code };
    });
  }

  /**
   * !This API will call when page set. When response came we are setting up data into the form
   */

  const { data: producer_data, refetch } = useViewProducer(producer_id);

  const {
    mutate: doStoreProducer,
    isLoading,
    error: addPRList,
    isError: addPRError
  } = useUpdateProducer(
    (res) => {
      toast.success(res.message);
      refetch();
      // window.location.reload();
      // navigate('');
    },
    (error) => {
      window.scrollTo(0, 0);
    }
  );

  const { isSuccess: containerListIsSuccess, data: containerListData } = useContainerListData();

  if (!producer_data) {
    return null;
  }

  const handleCancel = (dirty) => {
    if (dirty && dirty !== undefined) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="alert-box">
              <FontAwesomeIcon
                className="alert-close"
                icon={faClose}
                onClick={() => {
                  onClose();
                }}
              />
              <div className="alert-popup">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: t('page.reset_alert_popup_message')
                  }}></h2>
                <Button
                  className="table-delete-button"
                  onClick={() => {
                    onClose();
                    navigate(`/producer/list/`);
                  }}>
                  {t('page.alert_popup_yes_button')}
                </Button>
                <Button className="table-primary-button" onClick={onClose}>
                  {t('page.alert_popup_no_button')}
                </Button>
              </div>
            </div>
          );
        }
      });
    } else {
      navigate(`/producer/list/`);
    }
  };

  const containerData = [];
  if (containerListIsSuccess) {
    containerListData.data.map((value, key) => {
      const pq = producer_data.data.programmed_settings.filter(
        (option) => option.container_variable_id === value.container_variable_id
      );

      return containerData.push({
        container_quantity_checkbox: pq.length > 0 ? true : false,
        container_quantity: pq.length > 0 ? pq[0]['quantity'] : '',
        container_variable_id: value.container_variable_id,
        label: value.label,
        size: value.size
      });
    });
  }

  /**
   * !This block is for initial values
   */
  const initialValues = {
    // Detail Umum
    producer_id: producer_data.data.producer_id,
    name: producer_data.data.name,
    simpel_id: producer_data.data.simpel_id,
    simpel_registration_number: producer_data.data.simpel_registration_number,
    logo: producer_data.data.logo,
    logo_id: producer_data.data.logo_id,
    producer_npwp_number: producer_data.data.producer_npwp_number,
    country_id:
      producer_data.data.country_id !== '' && producer_data.data.country_id !== null
        ? producer_data.data.country_id
        : parseInt(process.env.MIX_DEFAULT_PHONE_CODE),
    phone: producer_data.data.phone,
    address: producer_data.data.address,
    address_notes: producer_data.data.address_notes,
    collection_address_notes:
      producer_data.data.collection_address_notes !== ''
        ? producer_data.data.collection_address_notes
        : producer_data.data.address_notes,
    city: producer_data.data.city,
    state: producer_data.data.state,
    postcode: producer_data.data.postcode,
    country: producer_data.data.country,
    latitude: producer_data.data.latitude,
    longitude: producer_data.data.longitude,
    producer_type_id: producer_data.data.producer_type_id,
    sub_producer_type_id: producer_data.data.sub_producer_type_id,
    licence_number: producer_data.data.licence_number,
    is_licence: producer_data.data.is_licence,
    contract_status: producer_data.data.contract_status,
    is_buy_container: producer_data.data.is_buy_container,
    is_collect_container: producer_data.data.is_collect_container,
    territory_id: producer_data.data.territory_id,
    area_id: producer_data.data.area_id,
    default_transporter: producer_data.data.default_transporter,

    // Detail Koleksi Bisnis
    type_collection: producer_data.data.type_collection,
    collect_frequence_type: producer_data.data.edit_collect_frequence_type_name,
    waste_codes: producer_data.data.waste_codes,
    collection_address:
      producer_data.data.collection_address !== ''
        ? producer_data.data.collection_address
        : producer_data.data.address,
    collection_city:
      producer_data.data.collection_city !== ''
        ? producer_data.data.collection_city
        : producer_data.data.city,
    collection_state:
      producer_data.data.collection_state !== ''
        ? producer_data.data.collection_state
        : producer_data.data.state,
    collection_postcode:
      producer_data.data.collection_postcode !== ''
        ? producer_data.data.collection_postcode
        : producer_data.data.postcode,
    collection_country:
      producer_data.data.collection_country !== ''
        ? producer_data.data.collection_country
        : producer_data.data.country,
    collection_latitude:
      producer_data.data.collection_latitude !== ''
        ? producer_data.data.collection_latitude
        : producer_data.data.latitude,
    collection_longitude:
      producer_data.data.collection_longitude !== ''
        ? producer_data.data.collection_longitude
        : producer_data.data.longitude,

    // Detail Bisnis Kerja
    days_slot_1:
      producer_data.data.days_slot_1.length > 0
        ? producer_data.data.days_slot_1
        : [
            {
              dayscheckbox: false,
              is_close: false,
              days_start: '',
              days_end: ''
            }
          ],
    days_slot_2:
      producer_data.data.days_slot_2.length > 0
        ? producer_data.data.days_slot_2
        : [
            {
              dayscheckbox: false,
              is_close: false,
              days_start: '',
              days_end: ''
            }
          ],
    days_slot_3:
      producer_data.data.days_slot_3.length > 0
        ? producer_data.data.days_slot_3
        : [
            {
              dayscheckbox: false,
              is_close: false,
              days_start: '',
              days_end: ''
            }
          ],
    days_slot_4:
      producer_data.data.days_slot_4.length > 0
        ? producer_data.data.days_slot_4
        : [
            {
              dayscheckbox: false,
              is_close: false,
              days_start: '',
              days_end: ''
            }
          ],
    days_slot_5:
      producer_data.data.days_slot_5.length > 0
        ? producer_data.data.days_slot_5
        : [
            {
              dayscheckbox: false,
              is_close: false,
              days_start: '',
              days_end: ''
            }
          ],
    days_slot_6:
      producer_data.data.days_slot_6.length > 0
        ? producer_data.data.days_slot_6
        : [
            {
              dayscheckbox: false,
              is_close: false,
              days_start: '',
              days_end: ''
            }
          ],
    days_slot_7:
      producer_data.data.days_slot_7.length > 0
        ? producer_data.data.days_slot_7
        : [
            {
              dayscheckbox: false,
              is_close: false,
              days_start: '',
              days_end: ''
            }
          ],
    // Detail Kontrak Produser
    contract_number: producer_data.data.contract_number,
    contract_start_date: producer_data.data.contract_start_date,
    contract_end_date: producer_data.data.contract_end_date,
    contract_note: producer_data.data.contract_note,
    agent_id: producer_data.data.agent_id,
    invoice_term_schedule_type: producer_data.data.invoice_term_schedule_type,
    invoice_term_schedule_day: producer_data.data.invoice_term_schedule_day,
    invoice_term_due_day: producer_data.data.invoice_term_due_day,

    // Detail Pemilik Produser
    owner_first_name:
      sectionCopy == 'ownerDetails'
        ? copyData.first_name
        : producer_data.data.owner_first_name === ''
        ? producer_data.data.user_first_name
        : producer_data.data.owner_first_name,
    owner_last_name:
      sectionCopy == 'ownerDetails'
        ? copyData.last_name
        : producer_data.data.owner_last_name === ''
        ? producer_data.data.user_last_name
        : producer_data.data.owner_last_name,
    owner_email:
      sectionCopy == 'ownerDetails'
        ? copyData.email
        : producer_data.data.owner_email === ''
        ? producer_data.data.user_email
        : producer_data.data.owner_email,
    owner_phone:
      sectionCopy == 'ownerDetails'
        ? copyData.phone
        : producer_data.data.owner_phone === ''
        ? producer_data.data.user_phone
        : producer_data.data.owner_phone,
    owner_ktp: producer_data.data.owner_ktp,

    // Detail Penanggung Jawab
    person_incharge_first_name:
      sectionCopy == 'personIncharge'
        ? copyData.first_name
        : producer_data.data.person_incharge_first_name === ''
        ? producer_data.data.user_first_name
        : producer_data.data.person_incharge_first_name,
    person_incharge_last_name:
      sectionCopy == 'personIncharge'
        ? copyData.last_name
        : producer_data.data.person_incharge_last_name === ''
        ? producer_data.data.user_last_name
        : producer_data.data.person_incharge_last_name,
    person_incharge_email:
      sectionCopy == 'personIncharge'
        ? copyData.email
        : producer_data.data.person_incharge_email === ''
        ? producer_data.data.user_email
        : producer_data.data.person_incharge_email,
    person_incharge_phone:
      sectionCopy == 'personIncharge'
        ? copyData.phone
        : producer_data.data.person_incharge_phone === ''
        ? producer_data.data.user_phone
        : producer_data.data.person_incharge_phone,

    // Detail Akunting
    accounting_first_name:
      sectionCopy == 'accountingDetails'
        ? copyData.first_name
        : producer_data.data.accounting_first_name === ''
        ? producer_data.data.user_first_name
        : producer_data.data.accounting_first_name,
    accounting_last_name:
      sectionCopy == 'accountingDetails'
        ? copyData.last_name
        : producer_data.data.accounting_last_name === ''
        ? producer_data.data.user_last_name
        : producer_data.data.accounting_last_name,
    accounting_email:
      sectionCopy == 'accountingDetails'
        ? copyData.email
        : producer_data.data.accounting_email === ''
        ? producer_data.data.user_email
        : producer_data.data.accounting_email,
    accounting_phone:
      sectionCopy == 'accountingDetails'
        ? copyData.phone
        : producer_data.data.accounting_phone === ''
        ? producer_data.data.user_phone
        : producer_data.data.accounting_phone,

    // Lampiran
    npwp_attchement: producer_data.data.npwp_attchement,
    npwp_id: producer_data.data.npwp_id,
    owner_ktp_attchment: producer_data.data.owner_ktp_attchment,
    ktp_id: producer_data.data.ktp_id,
    selfi_with_ktp_attchement: producer_data.data.selfi_with_ktp,
    selfi_with_ktp_id: producer_data.data.selfi_with_ktp_id,
    business_licence_attchement: producer_data.data.producer_licence,
    producer_licence_id: producer_data.data.producer_licence_id,
    photos_of_premises_attachment: producer_data.data.photos_of_premises_attachment,
    additional_attachement: producer_data.data.additional_attachment,
    contract_histories: producer_data.data.contract_histories,

    owner_country_id:
      producer_data.data.owner_country_id !== '' && producer_data.data.owner_country_id !== null
        ? producer_data.data.owner_country_id
        : parseInt(process.env.MIX_DEFAULT_PHONE_CODE),
    person_incharge_country_id:
      producer_data.data.person_incharge_country_id !== '' &&
      producer_data.data.person_incharge_country_id !== null
        ? producer_data.data.person_incharge_country_id
        : parseInt(process.env.MIX_DEFAULT_PHONE_CODE),
    accounting_country_id:
      producer_data.data.accounting_country_id !== '' &&
      producer_data.data.accounting_country_id !== null
        ? producer_data.data.accounting_country_id
        : parseInt(process.env.MIX_DEFAULT_PHONE_CODE),

    container_data: containerData,
    mou_contract: producer_data.data.mou_contract,
    surat_keputusan: producer_data.data.surat_keputusan,
    nomor_surat_keputusan: producer_data.data.nomor_surat_keputusan,
    mou_status: producer_data.data.mou_status,
    business_type: producer_data.data.business_type,
    referred_by: producer_data.data.referred_by,

    // Producer Invoice Address
    invoice_name: producer_data.data.invoice_address?.name ?? '',
    invoice_address: producer_data.data.invoice_address?.address ?? '',
    invoice_npwp: producer_data.data.invoice_address?.npwp ?? '',

    person_incharge_position: producer_data.data.person_incharge_position
  };

  const breadcurmArray = [
    {
      label: t('page.producer_list_label'),
      link: '/producer/list',
      active: ''
    },
    {
      label: t('page.edit_producer_title'),
      link: '/producer/list',
      active: 'active'
    }
  ];

  const stocks = {};
  for (const stock of producer_data.data.stock_details) {
    stocks[stock.container_variable_id] = stock.total_quantity;
  }

  return (
    <>
      <DialogLeavingPage
        showDialog={showDialogLeavingPage}
        setShowDialog={formState}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
        t={t}
      />
      <TNBreadCurm breadcurmArray={breadcurmArray} />
      <AlertCommon key={Math.floor(Math.random() * (1000 - 1 + 1) + 1)} is_error={addPRError}>
        {addPRList}
      </AlertCommon>
      <h1>{t('page.edit_producer_title')} </h1>
      <TabNavigation t={t} producer_id={producer_id} />
      <Card>
        <Card.Body className="p-0">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              const formData = new FormData();

              for (let key in values) {
                let value = values[key];

                if (key === 'additional_attachement') {
                  for (let keyatt in value) {
                    let valueatt = value[keyatt];
                    formData.append(
                      `additional_attachement[${keyatt}][additional_attachement_name]`,
                      valueatt.additional_attachement_name
                    );
                    formData.append(
                      `additional_attachement[${keyatt}][additional_attachement_file]`,
                      valueatt.additional_attachement_file
                    );
                    formData.append(
                      `additional_attachement[${keyatt}][additional_attachment_id]`,
                      valueatt.additional_attachment_id
                    );
                  }
                } else if (key === 'contract_histories') {
                  for (let keyatt in value) {
                    let valueatt = value[keyatt];
                    formData.append(
                      `contract_histories[${keyatt}][contract_histories_name]`,
                      valueatt.contract_histories_name
                    );
                    formData.append(
                      `contract_histories[${keyatt}][contract_histories_file]`,
                      valueatt.contract_histories_file
                    );
                    formData.append(
                      `contract_histories[${keyatt}][contract_histories_id]`,
                      valueatt.contract_histories_id
                    );
                  }
                } else if (key === 'photos_of_premises_attachment') {
                  for (let keycoll in value) {
                    let valuecolle = value[keycoll];
                    formData.append(
                      `photos_of_premises_attachment[${keycoll}]`,
                      valuecolle.photos_of_premises_attachment
                    );
                  }
                } else if (key === 'days_slot_1') {
                  formData.append(`dayscheckbox_1`, value[0].dayscheckbox);
                  formData.append(`is_close_1`, value[0].is_close);

                  for (let keyt in value) {
                    let valuet = value[keyt];
                    formData.append(`days_start_1[${keyt}]`, valuet.days_start);
                    formData.append(`days_end_1[${keyt}]`, valuet.days_end);
                    formData.append(
                      `producers_working_time_id_1[${keyt}]`,
                      valuet.producers_working_time_id
                    );
                  }
                } else if (key === 'days_slot_2') {
                  formData.append(`dayscheckbox_2`, value[0].dayscheckbox);
                  formData.append(`is_close_2`, value[0].is_close);
                  for (let keyt in value) {
                    let valuet = value[keyt];
                    formData.append(`days_start_2[${keyt}]`, valuet.days_start);
                    formData.append(`days_end_2[${keyt}]`, valuet.days_end);
                    formData.append(
                      `producers_working_time_id_2[${keyt}]`,
                      valuet.producers_working_time_id
                    );
                  }
                } else if (key === 'days_slot_3') {
                  formData.append(`dayscheckbox_3`, value[0].dayscheckbox);
                  formData.append(`is_close_3`, value[0].is_close);
                  for (let keyt in value) {
                    let valuet = value[keyt];
                    formData.append(`days_start_3[${keyt}]`, valuet.days_start);
                    formData.append(`days_end_3[${keyt}]`, valuet.days_end);
                    formData.append(
                      `producers_working_time_id_3[${keyt}]`,
                      valuet.producers_working_time_id
                    );
                  }
                } else if (key === 'days_slot_4') {
                  formData.append(`dayscheckbox_4`, value[0].dayscheckbox);
                  formData.append(`is_close_4`, value[0].is_close);
                  for (let keyt in value) {
                    let valuet = value[keyt];
                    formData.append(`days_start_4[${keyt}]`, valuet.days_start);
                    formData.append(`days_end_4[${keyt}]`, valuet.days_end);
                    formData.append(
                      `producers_working_time_id_4[${keyt}]`,
                      valuet.producers_working_time_id
                    );
                  }
                } else if (key === 'days_slot_5') {
                  formData.append(`dayscheckbox_5`, value[0].dayscheckbox);
                  formData.append(`is_close_5`, value[0].is_close);
                  for (let keyt in value) {
                    let valuet = value[keyt];
                    formData.append(`days_start_5[${keyt}]`, valuet.days_start);
                    formData.append(`days_end_5[${keyt}]`, valuet.days_end);
                    formData.append(
                      `producers_working_time_id_5[${keyt}]`,
                      valuet.producers_working_time_id
                    );
                  }
                } else if (key === 'days_slot_6') {
                  formData.append(`dayscheckbox_6`, value[0].dayscheckbox);
                  formData.append(`is_close_6`, value[0].is_close);
                  for (let keyt in value) {
                    let valuet = value[keyt];
                    formData.append(`days_start_6[${keyt}]`, valuet.days_start);
                    formData.append(`days_end_6[${keyt}]`, valuet.days_end);
                    formData.append(
                      `producers_working_time_id_6[${keyt}]`,
                      valuet.producers_working_time_id
                    );
                  }
                } else if (key === 'days_slot_7') {
                  formData.append(`dayscheckbox_7`, value[0].dayscheckbox);
                  formData.append(`is_close_7`, value[0].is_close);
                  for (let keyt in value) {
                    let valuet = value[keyt];
                    formData.append(`days_start_7[${keyt}]`, valuet.days_start);
                    formData.append(`days_end_7[${keyt}]`, valuet.days_end);
                    formData.append(
                      `producers_working_time_id_7[${keyt}]`,
                      valuet.producers_working_time_id
                    );
                  }
                } else if (key === 'container_data') {
                  for (let keyt in value) {
                    let valuet = value[keyt];
                    formData.append(
                      `container_data[${keyt}][container_quantity]`,
                      valuet.container_quantity
                    );
                    formData.append(
                      `container_data[${keyt}][container_quantity_checkbox]`,
                      valuet.container_quantity_checkbox
                    );
                    formData.append(
                      `container_data[${keyt}][container_variable_id]`,
                      valuet.container_variable_id
                    );
                  }
                } else if (key === 'collect_frequence_type') {
                  if (value === 'Day-1') {
                    formData.append('collect_frequence_type', '1');
                    formData.append('collection_frequence', '1');
                  } else if (value === 'Day-2') {
                    formData.append('collect_frequence_type', '1');
                    formData.append('collection_frequence', '2');
                  } else if (value === 'Week-1') {
                    formData.append('collect_frequence_type', '2');
                    formData.append('collection_frequence', '1');
                  } else if (value === 'Week-2') {
                    formData.append('collect_frequence_type', '2');
                    formData.append('collection_frequence', '2');
                  } else if (value === 'Month') {
                    formData.append('collect_frequence_type', '3');
                    formData.append('collection_frequence', '1');
                  }
                } else if (key === 'default_transporter') {
                  formData.append('default_transporter', value ?? '');
                } else if (key === 'referred_by') {
                  formData.append('referred_by', value ?? '');
                } else if (key === 'waste_codes') {
                  value.forEach((v, i) => {
                    formData.append(`waste_codes[${i}]`, v?.value);
                  });
                } else {
                  formData.append(key, value);
                }
              }

              if (values.contract_start_date !== '') {
                formData.append(
                  'contract_start_date',
                  moment(values.contract_start_date).format('YYYY-MM-DD')
                );
              }

              if (values.contract_end_date !== '') {
                formData.append(
                  'contract_end_date',
                  moment(values.contract_end_date).format('YYYY-MM-DD')
                );
              }

              setFormState(false);
              doStoreProducer(formData);
            }}>
            {({ values, errors, dirty }) => {
              values.contract_start_date =
                values.contract_start_date !== '' ? new Date(values.contract_start_date) : '';
              values.contract_end_date =
                values.contract_end_date !== '' ? new Date(values.contract_end_date) : '';
              return (
                <Form>
                  <Accordion
                    key={accrdKey}
                    alwaysOpen
                    defaultActiveKey={accrdKey && accrdKey !== 0 ? accrdKey : 1}>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 1}
                      className="my-1">
                      <Accordion.Header className="text-info fw-bold shadow-nond">
                        {t('page.add_producer_general_details_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <GeneralDetails
                          t={t}
                          countryCodeOptions={countryCodeOptions}
                          countryIsLoad={countryIsLoad}></GeneralDetails>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 2}
                      className="my-1">
                      <Accordion.Header className="text-info fw-bold shadow-nond">
                        {t('page.add_producer_collect_details_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <CollectDetails
                          t={t}
                          countryCodeOptions={countryCodeOptions}
                          countryIsLoad={countryIsLoad}
                          stocks={stocks}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 3}
                      className="my-1">
                      <Accordion.Header className="text-info fw-bold shadow-nond">
                        {t('page.add_producer_working_details_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <EditWorkingDetails
                          t={t}
                          countryCodeOptions={countryCodeOptions}
                          countryIsLoad={countryIsLoad}
                          refetch={refetch}></EditWorkingDetails>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 4}
                      className="my-1">
                      <Accordion.Header>
                        {t('page.add_producer_contact_details_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <ContractDetails
                          t={t}
                          copyData={copyData}
                          setCopyData={setCopyData}
                          paymentType={producer_data.data.payment_type}></ContractDetails>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 5}
                      className="my-1">
                      <Accordion.Header>
                        {t('page.add_producer_owner_details_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <OwnerDetails
                          t={t}
                          copyData={copyData}
                          setCopyData={setCopyData}
                          sectionCopy={sectionCopy}
                          setSectionCopy={setSectionCopy}
                          countryCodeOptions={countryCodeOptions}
                          countryIsLoad={countryIsLoad}></OwnerDetails>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 6}
                      className="my-1">
                      <Accordion.Header>
                        {t('page.add_producer_person_in_charge_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <PersonInCharge
                          t={t}
                          copyData={copyData}
                          setCopyData={setCopyData}
                          sectionCopy={sectionCopy}
                          setSectionCopy={setSectionCopy}
                          countryCodeOptions={countryCodeOptions}
                          countryIsLoad={countryIsLoad}></PersonInCharge>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 7}
                      className="my-1">
                      <Accordion.Header>
                        {t('page.add_producer_accounting_person_details_title')}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <AccountingPersonDetails
                          t={t}
                          copyData={copyData}
                          setCopyData={setCopyData}
                          sectionCopy={sectionCopy}
                          setSectionCopy={setSectionCopy}
                          countryCodeOptions={countryCodeOptions}
                          countryIsLoad={countryIsLoad}></AccountingPersonDetails>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      eventKey={accrdKey && accrdKey !== 0 ? accrdKey : 8}
                      className="border-bottom my-1">
                      <Accordion.Header>
                        {t('page.add_producer_attachment_title')}{' '}
                      </Accordion.Header>
                      <Accordion.Body className="p-0">
                        <EditAttachmentFiles t={t}></EditAttachmentFiles>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <div className="primary-button">
                    <span className="link-center" onClick={() => handleCancel(dirty)}>
                      {t('page.add_producer_cancel_button')}
                    </span>
                    <TNButton
                      loading={isLoading}
                      onClick={() => {
                        setAccrdKey(1);
                      }}
                      isdirtyform={dirty && dirty !== undefined ? 1 : 0}
                      type="submit">
                      {t('page.add_producer_submit_button')}
                    </TNButton>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Card.Body>
      </Card>
    </>
  );
};
EditProducerPage.propTypes = {
  t: PropTypes.func
};
export { EditProducerPage };
