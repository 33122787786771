import * as Yup from 'yup';
import moment from 'moment';

Yup.addMethod(Yup.string, 'phone', function (errorMessage) {
  return this.test(`test-phone`, errorMessage, function (value) {
    const phoneRegExp = /^\d{7,15}?$/;
    if (value && value.length > 0) {
      return phoneRegExp.test(value);
    }
    return true;
  });
});
Yup.addMethod(Yup.string, 'ktp_number', function (errorMessage) {
  return this.test(`test-ktp_number`, errorMessage, function (value) {
    const ktp_numberRegExp = /^\d{16}?$/;
    if (value && value.length > 0) {
      return ktp_numberRegExp.test(value);
    }
    return true;
  });
});
Yup.addMethod(Yup.string, 'alphaNumeric', function (errorMessage) {
  return this.test(`test-alphaNumeric`, errorMessage, function (value) {
    const alphaNumericRegExp = /^[aA-zZ0-9\s]+$/;
    if (value && value.length > 0) {
      return alphaNumericRegExp.test(value);
    }
    return true;
  });
});
Yup.addMethod(Yup.string, 'NumericCheck', function (errorMessage) {
  return this.test(`test-NumericCheck`, errorMessage, function (value) {
    const NumericRegExp = /^[0-9]+$/;
    if (value && value.length > 0) {
      return NumericRegExp.test(value);
    }
    return true;
  });
});
Yup.addMethod(Yup.string, 'npwp_number', function (errorMessage) {
  return this.test(`test-npwp_number`, errorMessage, function (value) {
    const npwp_numberRegExp = /^\d{2}(.\d{3})(.\d{3})(.\d{1})(-\d{3})(.\d{3})?$/;
    if (value && value.length > 0) {
      return npwp_numberRegExp.test(value);
    }
    return true;
  });
});
Yup.addMethod(Yup.mixed, 'file_type', function (errorMessage) {
  return this.test(`test-file_type`, errorMessage, function (value) {
    if (typeof value === 'string') {
      return true;
    }
    const isValid = ['image/jpg', 'image/jpeg', 'image/png'];
    if (value !== undefined && value !== '') {
      return value && isValid.includes(value.type);
    }
    return true;
  });
});
Yup.addMethod(Yup.mixed, 'file_typepdf', function (errorMessage) {
  return this.test(`test-file_type`, errorMessage, function (value) {
    if (typeof value === 'string') {
      return true;
    }
    const isValid = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];
    if (value !== undefined && value !== '') {
      return value && isValid.includes(value.type);
    }
    return true;
  });
});
Yup.addMethod(Yup.mixed, 'multiplefilecheck', function (errorMessage) {
  return this.test(`test-multiplefilecheck`, errorMessage, function (value) {
    const isValid = ['image/jpg', 'image/jpeg', 'image/png'];
    const checkarr = [];
    for (let key in value) {
      checkarr.push(value && isValid.includes(value[key].type));
    }
    if (checkarr.filter((item, i, ar) => ar.indexOf(item) === i).length > 1) {
      return false;
    }
    return true;
  });
});
Yup.addMethod(Yup.string, 'contract_status_should_active', function (errorMessage) {
  return this.test(`test-contract-status-should-active`, errorMessage, function (value) {
    if (value !== '3') {
      return false;
    }
    return true;
  });
});

export const validationSchema = Yup.object({
  // General Detail
  name: Yup.string().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (contract_status !== '1' || is_collect_container !== 0) {
        return Yup.string('page.add_producer_general_details_npwp_number_validation_required')
          .required('page.add_producer_general_details_producer_name_validation_required')
          .max(255, 'page.add_producer_general_details_producer_name_validation_max');
      } else {
        return Yup.string().notRequired();
      }
    }
  ),
  logo: Yup.mixed().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (contract_status !== '1' || is_collect_container !== 0) {
        return (
          Yup.mixed('page.add_factory_attachment_additional_attachement_file_required')
            // .required('page.add_producer_general_details_producer_logo_validation_required')
            .file_type('page.add_producer_general_details_producer_logo_validation_file_type')
        );
      } else {
        return Yup.mixed().notRequired();
      }
    }
  ),
  producer_npwp_number: Yup.string().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (contract_status !== '1' || is_collect_container !== 0) {
        return Yup.string('page.add_producer_general_details_npwp_number_validation_required')
          .npwp_number('page.add_producer_general_details_npwp_number_validation_regex')
          .test('len', 'page.add_producer_general_details_npwp_number_validation_regex', (len) => {
            if (len === undefined) {
              return true;
            }
            return len.length === 20;
          });
      } else {
        return Yup.string().notRequired();
      }
    }
  ),
  country_id: Yup.string().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (contract_status !== '1' || is_collect_container !== 0) {
        return Yup.string(
          'page.add_producer_general_details_general_country_code_validation_required'
        ).required('page.add_producer_general_details_general_country_code_validation_required');
      } else {
        return Yup.string().notRequired();
      }
    }
  ),
  phone: Yup.string().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (contract_status !== '1' || is_collect_container !== 0) {
        return Yup.string('page.add_producer_general_details_contact_number_validation_required')
          .required('page.add_producer_general_details_contact_number_validation_required')
          .NumericCheck('page.add_producer_general_details_contact_number_validation_digit')
          .min(7, 'page.add_producer_general_details_contact_number_validation_digit_limit')
          .max(15, 'page.add_producer_general_details_contact_number_validation_digit_limit');
      } else {
        return Yup.string().notRequired();
      }
    }
  ),
  producer_type_id: Yup.string().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (contract_status !== '1' || is_collect_container !== 0) {
        return Yup.string(
          'page.add_producer_general_details_producer_type_id_validation_required'
        ).required('page.add_producer_general_details_producer_type_id_validation_required');
      } else {
        return Yup.string().notRequired();
      }
    }
  ),
  licence_number: Yup.string().when(['is_licence'], {
    is: (is_licence) => is_licence === true,
    then: Yup.string('page.add_producer_general_details_licence_number_validation_required')
      .required('page.add_producer_general_details_licence_number_validation_required')
      .NumericCheck('page.add_producer_general_details_licence_number_validation_digit')
      .min(16, 'page.add_producer_general_details_licence_number_validation_digit_min')
      .max(20, 'page.add_producer_general_details_licence_number_validation_digit_max')
  }),
  address: Yup.string().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (contract_status !== '1' || is_collect_container !== 0) {
        return Yup.string('page.add_producer_general_details_address_validation_required').required(
          'page.add_producer_general_details_address_validation_required'
        );
      } else {
        return Yup.string().notRequired();
      }
    }
  ),
  address_notes: Yup.string().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (contract_status !== '1' || is_collect_container !== 0) {
        // return Yup.string(
        //   'page.add_producer_general_details_address_note_validation_required'
        // ).required('page.add_producer_general_details_address_note_validation_required');
        return Yup.string().notRequired();
      } else {
        return Yup.string().notRequired();
      }
    }
  ),
  contract_status: Yup.string()
    .when(['is_buy_container'], (is_buy_container) => {
      if (is_buy_container === 1) {
        return Yup.string().contract_status_should_active(
          'page.add_producer_general_details_status_validation_should_be_active_required'
        );
      }
    })
    .required('page.add_producer_general_details_status_validation_required'),
  // territory_id: Yup.string().when('contract_status', (contract_status) => {
  //   if (contract_status !== '1') {
  //     return Yup.string(
  //       'page.add_producer_collect_details_territory_name_validation_required'
  //     ).required('page.add_producer_collect_details_territory_name_validation_required');
  //   } else {
  //     return Yup.string().notRequired();
  //   }
  // }),
  territory_id: Yup.string().required(
    'page.add_producer_collect_details_territory_name_validation_required'
  ),
  // area_id: Yup.string().when('contract_status', (val, schema) => {
  //   if (val !== '1') {
  //     return Yup.string('page.add_producer_collect_details_area_name_validation_required').required(
  //       'page.add_producer_collect_details_area_name_validation_required'
  //     );
  //   } else {
  //     return Yup.string().notRequired();
  //   }
  // }),
  area_id: Yup.string().required('page.add_producer_collect_details_area_name_validation_required'),

  // Collect Details
  type_collection: Yup.string().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (is_collect_container !== 0) {
        return Yup.string(
          'page.add_producer_collect_details_type_collection_validation_required'
        ).required('page.add_producer_collect_details_type_collection_validation_required');
      } else {
        return Yup.string().notRequired();
      }
    }
  ),
  collect_frequence_type: Yup.string().when(['type_collection'], {
    is: (type_collection) => type_collection === '1',
    then: Yup.string(
      'page.add_producer_collect_details_collect_frequence_type_validation_required'
    ).required('page.add_producer_collect_details_collect_frequence_type_validation_required')
  }),
  waste_codes: Yup.array().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (is_collect_container !== 0) {
        return Yup.array()
          .min(1)
          .required('page.add_producer_collect_details_waste_code_id_validation_required');
      } else {
        return Yup.array().min(1).notRequired();
      }
    }
  ),
  collection_address: Yup.string().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (is_collect_container !== 0) {
        return Yup.string(
          'page.add_producer_collect_details_collection_address_validation_required'
        ).required('page.add_producer_collect_details_collection_address_validation_required');
      } else {
        return Yup.string().notRequired();
      }
    }
  ),
  collection_address_notes: Yup.string().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (is_collect_container !== 0) {
        // return Yup.string(
        //   'page.add_producer_general_details_address_note_validation_required'
        // ).required('page.add_producer_general_details_address_note_validation_required');
        return Yup.string().notRequired();
      } else {
        return Yup.string().notRequired();
      }
    }
  ),

  // Bisnis Detail
  days_slot_1: Yup.array().when(
    ['contract_status', 'is_collect_container', 'is_buy_container'],
    (contract_status, is_collect_container, is_buy_container) => {
      if (is_collect_container !== 0 || is_buy_container !== 0) {
        return Yup.array().of(
          Yup.object().shape({
            is_close: Yup.boolean(),
            dayscheckbox: Yup.boolean()
              .required('page.add_producer_work_details_day_1_checkbox_required')
              .oneOf([true], 'page.add_producer_work_details_day_1_checkbox_required'),
            days_start: Yup.mixed().when(['dayscheckbox', 'is_close'], {
              is: (is_close, dayscheckbox) => dayscheckbox === false && is_close === true,
              then: Yup.string()
                .required('page.add_producer_work_details_day_1_start_time_required')
                .test(
                  'not empty',
                  'page.add_producer_work_details_day_1_start_time_required',
                  function (value) {
                    return !!value;
                  }
                )
                .test(
                  'start_time_test',
                  'page.add_producer_work_details_day_1_start_time_before',
                  function (value) {
                    const { days_end } = this.parent;
                    if (days_end === undefined || days_end === '') {
                      return true;
                    }
                    return isSameOrBefore(value, days_end);
                  }
                )
              // .notRequired()
            }),
            days_end: Yup.mixed().when(['dayscheckbox', 'is_close'], {
              is: (is_close, dayscheckbox) => dayscheckbox === false && is_close === true,
              then: Yup.string()
                .required('page.add_producer_work_details_day_1_end_time_required')
                .test(
                  'not empty',
                  'page.add_producer_work_details_day_1_end_time_required',
                  function (value) {
                    return !!value;
                  }
                )
                .test(
                  'start_time_test',
                  'page.add_producer_work_details_day_1_end_time_after',
                  function (value) {
                    const { days_start } = this.parent;
                    if (days_start === undefined || days_start === '') {
                      return true;
                    }
                    return isSameOrAfter(value, days_start);
                  }
                )
              // .notRequired()
            })
          })
        );
      }
    }
  ),
  days_slot_2: Yup.array().when(
    ['contract_status', 'is_collect_container', 'is_buy_container'],
    (contract_status, is_collect_container, is_buy_container) => {
      if (is_collect_container !== 0 || is_buy_container !== 0) {
        return Yup.array().of(
          Yup.object().shape({
            is_close: Yup.boolean(),
            dayscheckbox: Yup.boolean()
              .required('page.add_producer_work_details_day_2_checkbox_required')
              .oneOf([true], 'page.add_producer_work_details_day_2_checkbox_required'),
            days_start: Yup.mixed().when(['dayscheckbox', 'is_close'], {
              is: (is_close, dayscheckbox) => dayscheckbox === false && is_close === true,
              then: Yup.string()
                .required('page.add_producer_work_details_day_2_start_time_required')
                .test(
                  'not empty',
                  'page.add_producer_work_details_day_2_start_time_required',
                  function (value) {
                    return !!value;
                  }
                )
                .test(
                  'start_time_test',
                  'page.add_producer_work_details_day_2_start_time_before',
                  function (value) {
                    const { days_end } = this.parent;
                    if (days_end === undefined || days_end === '') {
                      return true;
                    }
                    return isSameOrBefore(value, days_end);
                  }
                )
              // .notRequired()
            }),
            days_end: Yup.mixed().when(['dayscheckbox', 'is_close'], {
              is: (is_close, dayscheckbox) => dayscheckbox === false && is_close === true,
              then: Yup.string()
                .required('page.add_producer_work_details_day_2_end_time_required')
                .test(
                  'not empty',
                  'page.add_producer_work_details_day_2_end_time_required',
                  function (value) {
                    return !!value;
                  }
                )
                .test(
                  'start_time_test',
                  'page.add_producer_work_details_day_2_end_time_after',
                  function (value) {
                    const { days_start } = this.parent;
                    if (days_start === undefined || days_start === '') {
                      return true;
                    }
                    return isSameOrAfter(value, days_start);
                  }
                )
              // .notRequired()
            })
          })
        );
      }
    }
  ),
  days_slot_3: Yup.array().when(
    ['contract_status', 'is_collect_container', 'is_buy_container'],
    (contract_status, is_collect_container, is_buy_container) => {
      if (is_collect_container !== 0 || is_buy_container !== 0) {
        return Yup.array().of(
          Yup.object().shape({
            is_close: Yup.boolean(),
            dayscheckbox: Yup.boolean()
              .required('page.add_producer_work_details_day_3_checkbox_required')
              .oneOf([true], 'page.add_producer_work_details_day_3_checkbox_required'),
            days_start: Yup.mixed().when(['dayscheckbox', 'is_close'], {
              is: (is_close, dayscheckbox) => dayscheckbox === false && is_close === true,
              then: Yup.string()
                .required('page.add_producer_work_details_day_3_start_time_required')
                .test(
                  'not empty',
                  'page.add_producer_work_details_day_3_start_time_required',
                  function (value) {
                    return !!value;
                  }
                )
                .test(
                  'start_time_test',
                  'page.add_producer_work_details_day_3_start_time_before',
                  function (value) {
                    const { days_end } = this.parent;
                    if (days_end === undefined || days_end === '') {
                      return true;
                    }
                    return isSameOrBefore(value, days_end);
                  }
                )
              // .notRequired()
            }),
            days_end: Yup.mixed().when(['dayscheckbox', 'is_close'], {
              is: (is_close, dayscheckbox) => dayscheckbox === false && is_close === true,
              then: Yup.string()
                .required('page.add_producer_work_details_day_3_end_time_required')
                .test(
                  'not empty',
                  'page.add_producer_work_details_day_3_end_time_required',
                  function (value) {
                    return !!value;
                  }
                )
                .test(
                  'start_time_test',
                  'page.add_producer_work_details_day_3_end_time_after',
                  function (value) {
                    const { days_start } = this.parent;
                    if (days_start === undefined || days_start === '') {
                      return true;
                    }
                    return isSameOrAfter(value, days_start);
                  }
                )
              // .notRequired()
            })
          })
        );
      }
    }
  ),
  days_slot_4: Yup.array().when(
    ['contract_status', 'is_collect_container', 'is_buy_container'],
    (contract_status, is_collect_container, is_buy_container) => {
      if (is_collect_container !== 0 || is_buy_container !== 0) {
        return Yup.array().of(
          Yup.object().shape({
            is_close: Yup.boolean(),
            dayscheckbox: Yup.boolean()
              .required('page.add_producer_work_details_day_4_checkbox_required')
              .oneOf([true], 'page.add_producer_work_details_day_4_checkbox_required'),
            days_start: Yup.mixed().when(['dayscheckbox', 'is_close'], {
              is: (is_close, dayscheckbox) => dayscheckbox === false && is_close === true,
              then: Yup.string()
                .required('page.add_producer_work_details_day_4_start_time_required')
                .test(
                  'not empty',
                  'page.add_producer_work_details_day_4_start_time_required',
                  function (value) {
                    return !!value;
                  }
                )
                .test(
                  'start_time_test',
                  'page.add_producer_work_details_day_4_start_time_before',
                  function (value) {
                    const { days_end } = this.parent;
                    if (days_end === undefined || days_end === '') {
                      return true;
                    }
                    return isSameOrBefore(value, days_end);
                  }
                )
              // .notRequired()
            }),
            days_end: Yup.mixed().when(['dayscheckbox', 'is_close'], {
              is: (is_close, dayscheckbox) => dayscheckbox === false && is_close === true,
              then: Yup.string()
                .required('page.add_producer_work_details_day_4_end_time_required')
                .test(
                  'not empty',
                  'page.add_producer_work_details_day_4_end_time_required',
                  function (value) {
                    return !!value;
                  }
                )
                .test(
                  'start_time_test',
                  'page.add_producer_work_details_day_4_end_time_after',
                  function (value) {
                    const { days_start } = this.parent;
                    if (days_start === undefined || days_start === '') {
                      return true;
                    }
                    return isSameOrAfter(value, days_start);
                  }
                )
              // .notRequired()
            })
          })
        );
      }
    }
  ),
  days_slot_5: Yup.array().when(
    ['contract_status', 'is_collect_container', 'is_buy_container'],
    (contract_status, is_collect_container, is_buy_container) => {
      if (is_collect_container !== 0 || is_buy_container !== 0) {
        return Yup.array().of(
          Yup.object().shape({
            is_close: Yup.boolean(),
            dayscheckbox: Yup.boolean()
              .required('page.add_producer_work_details_day_5_checkbox_required')
              .oneOf([true], 'page.add_producer_work_details_day_5_checkbox_required'),
            days_start: Yup.mixed().when(['dayscheckbox', 'is_close'], {
              is: (is_close, dayscheckbox) => dayscheckbox === false && is_close === true,
              then: Yup.string()
                .required('page.add_producer_work_details_day_5_start_time_required')
                .test(
                  'not empty',
                  'page.add_producer_work_details_day_5_start_time_required',
                  function (value) {
                    return !!value;
                  }
                )
                .test(
                  'start_time_test',
                  'page.add_producer_work_details_day_5_start_time_before',
                  function (value) {
                    const { days_end } = this.parent;
                    if (days_end === undefined || days_end === '') {
                      return true;
                    }
                    return isSameOrBefore(value, days_end);
                  }
                )
              // .notRequired()
            }),
            days_end: Yup.mixed().when(['dayscheckbox', 'is_close'], {
              is: (is_close, dayscheckbox) => dayscheckbox === false && is_close === true,
              then: Yup.string()
                .required('page.add_producer_work_details_day_5_end_time_required')
                .test(
                  'not empty',
                  'page.add_producer_work_details_day_5_end_time_required',
                  function (value) {
                    return !!value;
                  }
                )
                .test(
                  'start_time_test',
                  'page.add_producer_work_details_day_5_end_time_after',
                  function (value) {
                    const { days_start } = this.parent;
                    if (days_start === undefined || days_start === '') {
                      return true;
                    }
                    return isSameOrAfter(value, days_start);
                  }
                )
              // .notRequired()
            })
          })
        );
      }
    }
  ),
  days_slot_6: Yup.array().when(
    ['contract_status', 'is_collect_container', 'is_buy_container'],
    (contract_status, is_collect_container, is_buy_container) => {
      if (is_collect_container !== 0 || is_buy_container !== 0) {
        return Yup.array().of(
          Yup.object().shape({
            is_close: Yup.boolean(),
            dayscheckbox: Yup.boolean()
              .required('page.add_producer_work_details_day_6_checkbox_required')
              .oneOf([true], 'page.add_producer_work_details_day_6_checkbox_required'),
            days_start: Yup.mixed().when(['dayscheckbox', 'is_close'], {
              is: (is_close, dayscheckbox) => dayscheckbox === false && is_close === true,
              then: Yup.string()
                .required('page.add_producer_work_details_day_6_start_time_required')
                .test(
                  'not empty',
                  'page.add_producer_work_details_day_6_start_time_required',
                  function (value) {
                    return !!value;
                  }
                )
                .test(
                  'start_time_test',
                  'page.add_producer_work_details_day_6_start_time_before',
                  function (value) {
                    const { days_end } = this.parent;
                    if (days_end === undefined || days_end === '') {
                      return true;
                    }
                    return isSameOrBefore(value, days_end);
                  }
                )
              // .notRequired()
            }),
            days_end: Yup.mixed().when(['dayscheckbox', 'is_close'], {
              is: (is_close, dayscheckbox) => dayscheckbox === false && is_close === true,
              then: Yup.string()
                .required('page.add_producer_work_details_day_6_end_time_required')
                .test(
                  'not empty',
                  'page.add_producer_work_details_day_6_end_time_required',
                  function (value) {
                    return !!value;
                  }
                )
                .test(
                  'start_time_test',
                  'page.add_producer_work_details_day_6_end_time_after',
                  function (value) {
                    const { days_start } = this.parent;
                    if (days_start === undefined || days_start === '') {
                      return true;
                    }
                    return isSameOrAfter(value, days_start);
                  }
                )
              // .notRequired()
            })
          })
        );
      }
    }
  ),
  days_slot_7: Yup.array().when(
    ['contract_status', 'is_collect_container', 'is_buy_container'],
    (contract_status, is_collect_container, is_buy_container) => {
      if (is_collect_container !== 0 || is_buy_container !== 0) {
        return Yup.array().of(
          Yup.object().shape({
            is_close: Yup.boolean(),
            dayscheckbox: Yup.boolean()
              .required('page.add_producer_work_details_day_7_checkbox_required')
              .oneOf([true], 'page.add_producer_work_details_day_7_checkbox_required'),
            days_start: Yup.mixed().when(['dayscheckbox', 'is_close'], {
              is: (is_close, dayscheckbox) => dayscheckbox === false && is_close === true,
              then: Yup.string()
                .required('page.add_producer_work_details_day_7_start_time_required')
                .test(
                  'not empty',
                  'page.add_producer_work_details_day_7_start_time_required',
                  function (value) {
                    return !!value;
                  }
                )
                .test(
                  'start_time_test',
                  'page.add_producer_work_details_day_7_start_time_before',
                  function (value) {
                    const { days_end } = this.parent;
                    if (days_end === undefined || days_end === '') {
                      return true;
                    }
                    return isSameOrBefore(value, days_end);
                  }
                )
              // .notRequired()
            }),
            days_end: Yup.mixed().when(['dayscheckbox', 'is_close'], {
              is: (is_close, dayscheckbox) => dayscheckbox === false && is_close === true,
              then: Yup.string()
                .required('page.add_producer_work_details_day_7_end_time_required')
                .test(
                  'not empty',
                  'page.add_producer_work_details_day_7_end_time_required',
                  function (value) {
                    return !!value;
                  }
                )
                .test(
                  'start_time_test',
                  'page.add_producer_work_details_day_7_end_time_after',
                  function (value) {
                    const { days_start } = this.parent;
                    if (days_start === undefined || days_start === '') {
                      return true;
                    }
                    return isSameOrAfter(value, days_start);
                  }
                )
              // .notRequired()
            })
          })
        );
      }
    }
  ),

  // Contract Details
  contract_number: Yup.string().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      // if (val !== '1') {
      // return Yup.string('page.add_producer_contact_details_contract_number_validation_required')
      //   .required('page.add_producer_contact_details_contract_number_validation_required')
      //   .alphaNumeric(
      //     'page.add_producer_general_details_producer_contract_number_validation_alphaNumeric'
      //   );
      // } else {
      //   return Yup.string().notRequired();
      // }
      if (is_collect_container !== 0) {
        return Yup.string(
          'page.add_producer_contact_details_contract_number_validation_required'
        ).required('page.add_producer_contact_details_contract_number_validation_required');
      } else {
        return Yup.string().notRequired();
      }
    }
  ),
  contract_start_date: Yup.date().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (is_collect_container !== 0) {
        return (
          Yup.date()
            // .test(
            //   'not empty',
            //   'page.add_producer_contract_details_contract_start_date_validation_required',
            //   function (value) {
            //     return !!value;
            //   }
            // )
            .test(
              'start_date_test',
              'page.add_producer_contract_details_contract_start_date_validation_max',
              function (value) {
                const { contract_end_date } = this.parent;
                if (contract_end_date === undefined || contract_end_date === '') {
                  return true;
                }
                return isSameOrBeforeDate(value, contract_end_date);
              }
            )
        );
      }
    }
  ),
  contract_end_date: Yup.date().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (is_collect_container !== 0) {
        return Yup.date().test(
          'not empty',
          'page.add_producer_contract_details_contract_end_date_validation_required',
          function (value) {
            return !!value;
          }
        );
        //       .test(
        //         'end_time_test',
        //         'page.add_producer_contract_details_contract_end_date_validation_min',
        //         function (value) {
        //           const { contract_start_date } = this.parent;
        //           if (contract_start_date === undefined || contract_start_date === '') {
        //             return true;
        //           }
        //           return isSameOrAfterDate(value, contract_start_date);
        //         }
        //       );
      }
    }
  ),
  surat_keputusan: Yup.string().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (is_collect_container !== 0) {
        return Yup.string('page.add_producer_surat_keputusan_validation_required').required(
          'page.add_producer_surat_keputusan_validation_required'
        );
      } else {
        return Yup.string().notRequired();
      }
    }
  ),
  nomor_surat_keputusan: Yup.string().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (is_collect_container !== 0) {
        return Yup.string('page.add_producer_nomor_surat_keputusan_validation_required').required(
          'page.add_producer_nomor_surat_keputusan_validation_required'
        );
      } else {
        return Yup.string().notRequired();
      }
    }
  ),
  agent_id: Yup.number(),

  // Owner Details
  owner_first_name: Yup.string().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (is_collect_container !== 0) {
        return Yup.string('page.add_producer_owner_details_first_name_validation_required')
          .required('page.add_producer_owner_details_first_name_validation_required')
          .max(50, 'page.add_producer_owner_details_first_name_validation_max');
      } else {
        return Yup.string().notRequired();
      }
    }
  ),
  owner_last_name: Yup.string().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (is_collect_container !== 0) {
        return Yup.string('page.add_producer_owner_details_last_name_validation_required')
          .required('page.add_producer_owner_details_last_name_validation_required')
          .max(50, 'page.add_producer_owner_details_last_name_validation');
      } else {
        return Yup.string().notRequired();
      }
    }
  ),
  owner_email: Yup.string().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (is_collect_container !== 0) {
        return Yup.string('page.add_producer_owner_details_email_address_validation_required')
          .email('page.add_producer_owner_details_email_address_validation_email')
          .required('page.add_producer_owner_details_email_address_validation_required')
          .max(50, 'page.add_producer_owner_details_email_address_validation_max');
      } else {
        return Yup.string().notRequired();
      }
    }
  ),
  owner_country_id: Yup.string().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (is_collect_container !== 0) {
        return Yup.string(
          'page.add_producer_owner_details_country_code_validation_required'
        ).required('page.add_producer_owner_details_country_code_validation_required');
      } else {
        return Yup.string().notRequired();
      }
    }
  ),
  owner_phone: Yup.string().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (is_collect_container !== 0) {
        return Yup.string('page.add_producer_owner_details_contact_number_validation_required')
          .required('page.add_producer_owner_details_contact_number_validation_required')
          .NumericCheck('page.add_producer_owner_details_contact_number_validation_digit')
          .min(7, 'page.add_producer_owner_details_contact_number_validation_digit_limit')
          .max(15, 'page.add_producer_owner_details_contact_number_validation_digit_limit');
      } else {
        return Yup.string().notRequired();
      }
    }
  ),
  owner_ktp: Yup.string().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (is_collect_container !== 0) {
        return Yup.string('page.add_producer_owner_details_ktp_number_validation_required')
          .NumericCheck('page.add_producer_owner_details_ktp_number_validation_digit')
          .min(16, 'page.add_producer_owner_details_ktp_number_validation_digit_limit')
          .max(16, 'page.add_producer_owner_details_ktp_number_validation_digit_limit');
      } else {
        return Yup.string().notRequired();
      }
    }
  ),

  // Person In Charge
  person_incharge_first_name: Yup.string().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (is_collect_container !== 0) {
        return Yup.string('page.add_producer_person_incharge_first_name_validation_required')
          .required('page.add_producer_person_incharge_first_name_validation_required')
          .max(50, 'page.add_producer_person_incharge_first_name_validation_max');
      } else {
        return Yup.string().notRequired();
      }
    }
  ),
  person_incharge_last_name: Yup.string().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (is_collect_container !== 0) {
        return Yup.string('page.add_producer_person_incharge_last_name_validation_required')
          .required('page.add_producer_person_incharge_last_name_validation_required')
          .max(50, 'page.add_producer_person_incharge_last_name_validation_max');
      } else {
        return Yup.string().notRequired();
      }
    }
  ),
  person_incharge_email: Yup.string().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (is_collect_container !== 0) {
        return Yup.string('page.add_producer_person_incharge_email_validation_required')
          .email('page.add_producer_person_incharge_email_validation_email')
          .required('page.add_producer_person_incharge_email_validation_required')
          .max(50, 'page.add_producer_person_incharge_email_validation_max');
      } else {
        return Yup.string().notRequired();
      }
    }
  ),
  person_incharge_country_id: Yup.string().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (is_collect_container !== 0) {
        return Yup.string(
          'page.add_producer_person_incharge_country_id_validation_required'
        ).required('page.add_producer_person_incharge_country_id_validation_required');
      } else {
        return Yup.string().notRequired();
      }
    }
  ),
  person_incharge_phone: Yup.string().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (is_collect_container !== 0) {
        return Yup.string('page.add_producer_person_incharge_phone_validation_required')
          .required('page.add_producer_person_incharge_phone_validation_required')
          .NumericCheck('page.add_producer_person_incharge_phone_validation_digit')
          .min(7, 'page.add_producer_person_incharge_phone_validation_digit_limit')
          .max(15, 'page.add_producer_person_incharge_phone_validation_digit_limit');
      } else {
        return Yup.string().notRequired();
      }
    }
  ),

  // Acounting Person Details
  accounting_first_name: Yup.string().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (is_collect_container !== 0) {
        return Yup.string('page.add_producer_accounting_first_name_validation_required')
          .required('page.add_producer_accounting_first_name_validation_required')
          .max(50, 'page.add_producer_accounting_first_name_validation_max');
      } else {
        return Yup.string().notRequired();
      }
    }
  ),
  accounting_last_name: Yup.string().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (is_collect_container !== 0) {
        return Yup.string('page.add_producer_accounting_last_name_validation_required')
          .required('page.add_producer_accounting_last_name_validation_required')
          .max(50, 'page.add_producer_accounting_last_name_validation_max');
      } else {
        return Yup.string().notRequired();
      }
    }
  ),
  accounting_email: Yup.string().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (is_collect_container !== 0) {
        return Yup.string('page.add_producer_accounting_email_validation_required')
          .email('page.add_producer_accounting_email_validation_email')
          .required('page.add_producer_accounting_email_validation_required')
          .max(50, 'page.add_producer_accounting_email_validation_max');
      } else {
        return Yup.string().notRequired();
      }
    }
  ),
  accounting_country_id: Yup.string().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (is_collect_container !== 0) {
        return Yup.string('page.add_producer_accounting_country_id_validation_required').required(
          'page.add_producer_accounting_country_id_validation_required'
        );
      } else {
        return Yup.string().notRequired();
      }
    }
  ),
  accounting_phone: Yup.string().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (is_collect_container !== 0) {
        return Yup.string('page.add_producer_accounting_phone_validation_required')
          .required('page.add_producer_accounting_phone_validation_required')
          .NumericCheck('page.add_producer_accounting_phone_validation_digit')
          .min(7, 'page.add_producer_accounting_phone_validation_digit_limit')
          .max(15, 'page.add_producer_accounting_phone_validation_digit_limit');
      } else {
        return Yup.string().notRequired();
      }
    }
  ),

  // Lampiran
  owner_ktp_attchment: Yup.mixed().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (is_collect_container !== 0) {
        return Yup.mixed('page.add_producer_attachment_owner_ktp_attchment_required')
          .required('page.add_producer_attachment_owner_ktp_attchment_required')
          .file_type('page.add_producer_attachment_owner_ktp_attchment_file_type');
      } else {
        return Yup.mixed().notRequired();
      }
    }
  ),
  selfi_with_ktp_attchement: Yup.mixed().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (is_collect_container !== 0) {
        return Yup.mixed('page.add_producer_attachment_selfi_with_ktp_attchement_file_required')
          .notRequired('page.add_producer_attachment_selfi_with_ktp_attchement_file_required')
          .file_type('page.add_producer_attachment_selfi_with_ktp_attchement_file_type');
      } else {
        return Yup.mixed().notRequired();
      }
    }
  ),
  npwp_attchement: Yup.mixed().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (is_collect_container !== 0) {
        return (
          Yup.mixed('page.add_producer_attachment_npwp_attchement_required')
            // .required('page.add_producer_attachment_npwp_attchement_required')
            .file_type('page.add_producer_attachment_npwp_attchement_file_type')
        );
      } else {
        return Yup.mixed().notRequired();
      }
    }
  ),
  photos_of_premises_attachment: Yup.array().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (is_collect_container !== 0) {
        return (
          Yup.array()
            // .required('page.add_producer_attachment_photos_of_premises_attachment_required')
            // .min(1, 'page.add_producer_attachment_photos_of_premises_attachment_required')
            .multiplefilecheck(
              'page.add_producer_attachment_photos_of_premises_attachment_file_type'
            )
        );
      } else {
        return Yup.array().notRequired();
      }
    }
  ),
  business_licence_attchement: Yup.mixed().when(
    ['contract_status', 'is_collect_container'],
    (contract_status, is_collect_container) => {
      if (is_collect_container !== 0) {
        return Yup.mixed().file_type(
          'page.add_producer_attachment_business_licence_attchement_file_type'
        );
      } else {
        return Yup.string().notRequired();
      }
    }
  ),
  // business_licence_attchement: Yup.mixed().file_type(
  //   'page.add_producer_attachment_business_licence_attchement_file_type'
  // ),
  additional_attachement: Yup.array().of(
    Yup.object().shape({
      additional_attachement_name: Yup.string()
        .trim()
        .required('page.add_producer_attachment_additional_attachement_name_required'),
      additional_attachement_file: Yup.mixed()
        .required('page.add_producer_attachment_additional_attachement_file_required')
        .file_typepdf('page.add_producer_attachment_additional_attachement_file_file_type')
    })
  ),

  container_data: Yup.array().of(
    Yup.object().shape({
      container_quantity_checkbox: Yup.boolean(),
      container_quantity: Yup.mixed().when(['container_quantity_checkbox'], {
        is: (container_quantity_checkbox) => container_quantity_checkbox === true,
        then: Yup.string().required('page.add_producer_container_quantity_required')
      })
    })
  ),

  sub_producer_type_id: Yup.number().required()
});

const isSameOrBefore = (startTime, endTime) => {
  return moment(startTime, 'HH:mm').isBefore(moment(endTime, 'HH:mm'));
};

const isSameOrAfter = (startTime, endTime) => {
  return moment(startTime, 'HH:mm').isAfter(moment(endTime, 'HH:mm'));
};

const isSameOrBeforeDate = (startTime, endTime) => {
  return moment(startTime, 'YYYY-MM-DD').isSameOrBefore(moment(endTime, 'YYYY-MM-DD'));
};

// const isSameOrAfterDate = (startTime, endTime) => {
//   return moment(startTime, 'YYYY-MM-DD').isSameOrAfter(moment(endTime, 'YYYY-MM-DD'));
// };
